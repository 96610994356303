<!--
 * @Author: wuqi
 * @Date: 2021-12-25 11:40:12
 * @LastEditors: wuqi
 * @LastEditTime: 2021-12-31 09:51:39
 * @Description:
-->
<template>
    <el-popover
      placement="bottom-start"
      trigger="click"
      @show='showCallback'
      >
      <div class='icon-wrap'>
        <div class='icon-wrap-img'>
          <span class='item ' v-for="iconItem in icons" :class="{active:modelValue.showIcon===iconItem}"  :key="iconItem" @click="setCustomIcon(iconItem)">
            <en-icon :name='iconPrefix+iconItem+iconSuffix' size="14px" :style="{color:modelValue.showIcon===iconItem?'#fff':'#179eda'}"></en-icon>
          </span>
        </div>
        <div class='icon-wrap-color'>
          <span class='item' v-for="(colorItem,ind) in colors" :key="ind">
            <span class='ring' :style="{background:colorItem}" @click="setCustomColor(colorItem)">
              <en-icon v-if="modelValue.color===colorItem" name='tongyianniu-kuaijiecaozuo' size='14px'></en-icon>
            </span>
          </span>
        </div>
      </div>
      <span slot="reference" class='show-icon'
            :style="{'background-color':modelValue.color}">
            <en-icon  :name="iconPrefix+modelValue.showIcon+iconSuffix" size="18px" style="color:#fff" ></en-icon>
      </span>
    </el-popover>
</template>

<script>
export default {
  name: "icon-color-picker",
  model: {
    prop: "value",
    event: "update"
  },
  props: {
    value: Object,
    colors: {
      type: Array,
      default() {
        return [];
      }
    },
    icons: {
      type: Array,
      default() {
        return [];
      }
    },
    iconPrefix: {
      type: String,
      default: ""
    },
    iconSuffix: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      modelValue: {
        showIcon: this.icons[0],
        color: this.colors[0]
      }
    };
  },
  computed: {
  },
  created() {
  },
  mounted() {
    this.modelValue = { ...this.modelValue, ...this.value };
  },
  methods: {
    setCustomColor(color) {
      this.modelValue.color = color;
      this.$emit("update", this.modelValue);
    },
    showCallback() {
      this.modelValue = { ...this.modelValue, ...this.value };
    },
    setCustomIcon(icon) {
      this.modelValue.showIcon = icon;
      this.$emit("update", this.modelValue);
    }
  }
};
</script>

<style lang='scss' scoped>
.show-icon{
  width: 30px;
  height: 30px;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 6px;
}
</style>
