<template>
    <div class="menu-define-item">
      <el-checkbox class='check-left' v-if="checkBox" :class="isDraggable ? 'isDraggable':''"  v-model="selected" @change="change"></el-checkbox>
      <div class="bgContent" :draggable="draggableFlg" :class="isDraggable ? 'isDraggable':''">
        <div class="content">
          <div class="content-icon" :style="'background-color:'+ color">
            <en-icon :name="icon"></en-icon>
          </div>
          <div class="content-text">{{ menuData.name }}</div>
        </div>

        <el-dropdown   placement='bottom-start'  class='right-edit' v-if="flgCustom&&!checkBox">
          <en-icon name='gengduo-liebiaoyouce'  v-if="flgCustom" size='18px' style="color:#bbb"></en-icon>
          <el-dropdown-menu     slot="dropdown">
            <el-dropdown-item  v-if="parentType==8">
              <en-icon-text name='bianji' size='14px' color='#26c494' :label='"编辑"' @click.native="editItem"></en-icon-text>
            </el-dropdown-item>
            <el-dropdown-item>
              <en-icon-text name='shanchu' size='14px' color='#f36e6b' :label='"删除"' @click.native="deleteItem"></en-icon-text>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
</template>

<script>
import enIconText from "@/components/en-icon-text";

export default {
  name: "menu-define-item",
  components: {
    enIconText
  },
  props: {
    menuData: {
      type: Object
    },
    isDraggable: {
      type: Boolean,
      default: false
    },
    checkBox: {
      type: Boolean,
      default: true
    },
    draggableFlg: {
      type: Boolean,
      default: true
    },
    isCommon: {
      type: Boolean,
      default: false
    },
    parentType: Number
  },
  created() {
    this.updateSeleted();
  },
  watch: {
    isShow() {
      this.updateSeleted();
    }
  },
  computed: {
    isShow() {
      return this.menuData.isShow === 0;
    },
    common() {
      return this.menuData.isCommon === "000";
    },
    icon() {
      if (this.menuData?.showIcon) {
        return this.menuData?.showIcon;
      }
      if (this.menuData?.customIcon && this.menuData?.customIcon.indexOf("-shixin") > -1) {
        return this.menuData.customIcon.replace("-shixin", "-duose");
      }
      return this.menuData.customIcon;
    },
    color() {
      return this.menuData.color || this.menuData.customColor;
    },
    flgCustom() {
      return this.parentType === 1 || this.parentType === 8;
    }

  },
  data() {
    return {
      selected: false
    };
  },
  methods: {
    updateSeleted() {
      this.selected = this.isCommon ? this.common : this.isShow;
    },
    editItem() {
      this.$emit("edit");
    },
    deleteItem() {
      this.$emit("delete", this.menuData);
    },
    change() {
      if (this.isCommon) {
        this.menuData.isCommon = this.selected ? "000" : "001";
      } else {
        this.menuData.isShow = this.selected ? 0 : 1;
      }
      this.$emit("change");
    }
  }
};
</script>
<style>
body {
   -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by most modern browsers */
}
:focus{
  outline: none;
}
</style>

<style lang="scss" scoped>

.menu-define-item {
  display: inline-flex;
  align-items: center;
  margin-bottom: 16px;
  .el-checkbox {
    margin-left: 16px;
  }
  .isDraggable {
    &.el-checkbox {
      visibility: hidden;
    }
    .content {
      visibility: hidden;
    }
  }
  .check-left{
    margin-right: 16px;
  }
  .bgContent {
    background-color: #E1E8F1;
    margin-right: 16px;
    border-radius:4px;
    width: 220px;
    position: relative;
    .content {
      border-radius:4px;
      width: 100%;
      display: flex;
      align-items: center;
      height: 80px;
      background-color: #FFFFFF;
      background:rgba(255,255,255,1);
      border:1px solid rgba(231,235,241,1);
      .content-text {
        width: 135px;
        font-size:12px;
        color: #333333;
        margin-left: 12px;
        
      }
      .content-icon {
        background-color: #F76B6B;
        width: 40px;
        height: 40px;
        text-align: center;
        border-radius:50%;
        display: inline-flex;//flex布局
        justify-content: center;//x轴排列
        align-items: center;//y轴排列
        margin-left: 17px;
        .en-icon {
          width: 18px;
          height: 18px;
          color: white;
          border-radius:3px;
        }
      }
    }
    .content:hover{
      background: #F5F9FD;
    border: 1px solid #E8ECF2;
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
    border-radius: 4px;
    }
    .right-edit{
      position:absolute;
      right: 10px;
      top: 10px;
    }
  }
}
</style>
