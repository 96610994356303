<template>
  <div class="my-en">
    <!-- Start 我的任务 -->
    <div class="my-en-item">
      <en-title-card
        name="我的任务"
      >
        <div slot="right">
          <en-icon
            size="small"
            name="fanhui"
            @click.native="changePage('/myEn/myTask')"
          >
          </en-icon>
        </div>
      </en-title-card>
      <div class="my-en-item-body">
        <div
          v-for="item in myTask.records"
          :key="item.id"
          class="my-en-item-data"
        >
          <div class="my-en-item-data-basic">
            <div
              class="my-en-item-data-icon"
            >
              <en-icon
                name="shujuxiangqing"
              >
              </en-icon>
            </div>
            <div class="my-en-item-data-name">
              {{ item.name }}
            </div>
          </div>
          <div class="my-en-item-data-status">
            <div class="my-en-item-data-date">
              {{ item.endTime }}
            </div>
            <el-tag>
              {{ item.billStatusName }}
            </el-tag>
          </div>
        </div>
      </div>
      <div
        v-if="myTask.totalPage > 1"
        class="my-en-item-more"
      >
        加载更多({{ myTask.total - myTaskPage * 5 }})
      </div>
    </div>
    <!-- End 我的任务 -->

    <!-- Start 我的业务 -->
    <div class="my-en-item">
      <en-title-card
        name="我的业务"
      >
        <div slot="right">
          <en-icon
            size="small"
            name="fanhui"
            @click.native="changePage('/myEn/myBusiness')"
          >
          </en-icon>
        </div>
      </en-title-card>
      <div class="my-en-item-body">
        <div
          v-for="item in myBusiness.records"
          :key="item.id"
          class="my-en-item-data"
        >
          <div class="my-en-item-data-basic">
            <div
              class="my-en-item-data-icon"
              :style="{'background-color':iconReplaceBg(item.code) }"
            >
              <en-icon
                name="shujuxiangqing"
              >
              </en-icon>
            </div>
            <div class="my-en-item-data-name">
              {{ item.templateName }}
            </div>
          </div>
          <div class="my-en-item-data-status">
            <div class="my-en-item-data-date">
              {{ item.endTime }}
            </div>
            <el-tag>
              {{ item.billStatusName }}
            </el-tag>
          </div>
        </div>
      </div>
      <div
        v-if="myBusiness.totalPage > 1"
        class="my-en-item-more"
      >
        加载更多({{ myBusiness.total - myBusinessPage * 5 }})
      </div>
    </div>
    <!-- End 我的业务 -->

    <!-- Start 我的订单 -->
    <div class="my-en-item">
      <en-title-card
        name="我的订单"
      >
        <div slot="right">
          <en-icon
            size="small"
            name="fanhui"
            @click.native="changePage('/myEn/myOrder')"
          >
          </en-icon>
        </div>
      </en-title-card>
      <div class="my-en-item-body">
        <div
          v-for="item in myOrder.records"
          :key="item.id"
          class="my-en-item-data"
        >
          <div class="my-en-item-data-basic">
            <div
              class="my-en-item-data-icon"
              :style="{'background-color':iconReplaceBg(item.code) }"
            >
              <en-icon
                name="shujuxiangqing"
              >
              </en-icon>
            </div>
            <div class="my-en-item-data-name">
              {{ item.abstract }}
            </div>
          </div>
          <div class="my-en-item-data-status">
            <div class="my-en-item-data-date">
              {{ item.endTime }}
            </div>
            <el-tag>
              {{ item.billStatusName }}
            </el-tag>
          </div>
        </div>
      </div>
      <div
        v-if="myOrder.totalPage > 1"
        class="my-en-item-more"
      >
        加载更多({{ myOrder.total - myOrderPage * 5 }})
      </div>
    </div>
    <!-- End 我的订单 -->

    <!-- Start 我的消息 -->
    <div class="my-en-item">
      <en-title-card
        name="我的消息"
      >
        <div slot="right">
          <en-icon
            size="small"
            name="fanhui"
            @click.native="changePage('/myEn/myNotice')"
          >
          </en-icon>
        </div>
      </en-title-card>
      <div class="my-en-item-body">
        <div
          v-for="item in myNotice.records"
          :key="item.id"
          class="my-en-item-data"
        >
          <div class="my-en-item-data-basic">
            <div
              class="my-en-item-data-icon"
              :style="{'background-color':iconReplaceBg(item.code) }"
            >
              <en-icon
                name="shujuxiangqing"
              >
              </en-icon>
            </div>
            <div class="my-en-item-data-name">
              {{ item.message }}
            </div>
          </div>
          <div class="my-en-item-data-status">
            <div class="my-en-item-data-date">
              {{ item.endTime }}
            </div>
            <el-tag>
              {{ item.billStatusName }}
            </el-tag>
          </div>
        </div>
      </div>
      <div
        v-if="myNotice.totalPage > 1"
        class="my-en-item-more"
      >
        加载更多({{ myNotice.total - myNoticePage * 5 }})
      </div>
    </div>
    <!-- End 我的消息 -->

    <!-- Start 已办事项 -->
    <div class="my-en-item">
      <en-title-card
        name="已办事项"
      >
        <div slot="right">
          <en-icon
            size="small"
            name="fanhui"
            @click.native="changePage('/myEn/myTaskList')"
          >
          </en-icon>
        </div>
      </en-title-card>
      <div class="my-en-item-body">
        <div
          v-for="item in taskList.records"
          :key="item.id"
          class="my-en-item-data"
        >
          <div class="my-en-item-data-basic">
            <div
              class="my-en-item-data-icon"
              :style="{'background-color':iconReplaceBg(item.code) }"
            >
              <en-icon
                name="shujuxiangqing"
              >
              </en-icon>
            </div>
            <div class="my-en-item-data-name">
              {{ item.message }}
            </div>
          </div>
          <div class="my-en-item-data-status">
            <div class="my-en-item-data-date">
              {{ item.endTime }}
            </div>
            <el-tag>
              {{ item.billStatusName }}
            </el-tag>
          </div>
        </div>
      </div>
      <div
        v-if="taskList.totalPage > 1"
        class="my-en-item-more"
      >
        加载更多({{ taskList.total - taskListPage * 5 }})
      </div>
    </div>
    <!-- End 已办事项 -->

    <!-- 日历 -->
    <date-picker></date-picker>
  </div>
</template>

<script>
import myTask from "./data/myTask";
import myOrder from "./data/myOrder";
import myNotice from "./data/myNotice";
import taskList from "./data/taskList";
import myBusiness from "./data/myBusiness";
import datePicker from "./datePicker";

export default {
  name: "MyEn",
  components: { datePicker },
  data() {
    return {
      taskList,
      myTask,
      myOrder,
      myNotice,
      myBusiness,
      taskListPage: 1,
      myTaskPage: 1,
      myOrderPage: 1,
      myNoticePage: 1,
      myBusinessPage: 1
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    /**
     * @description 进入详情界面
     * @param routePath
     */
    changePage(routePath) {
      this.$router.push({ path: routePath });
    },
    /**
     * @description 获取数据清单
     */
    getDataList() {
      this.myTask = myTask;
      this.myOrder = myOrder;
      this.myNotice = myNotice;
      this.myBusiness = myBusiness;
      this.taskList = taskList;
    }
  }
};
</script>

<style lang="scss" scoped>
  .my-en {
    overflow-y: auto;
    background-color: #232c3d;

    .my-en-item {
      width: 100%;
      margin-bottom: 10px;

      .en-title-card {
        position: relative;

        .en-icon {
          position: absolute;
          right: 20px;
          color: #A9B5C6;
          top: 15px;
          transform: rotate(180deg);
          cursor: pointer;
        }
      }

      .my-en-item-body {
        background-color: #ffffff;
        -webkit-border-radius: 0 0 4px 4px;
        -moz-border-radius: 0 0 4px 4px;
        border-radius: 0 0 4px 4px;
        width: 100%;
        padding: 0 10px;

        .my-en-item-data {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 15px 10px;
          border-bottom: 1px solid #E8ECF2;

          .my-en-item-data-basic {
            display: flex;
            justify-content: flex-start;

            .my-en-item-data-icon {
              width: 40px;
              height: 40px;
              background-color: #26C393;
              color: #ffffff;
              -webkit-border-radius: 100%;
              -moz-border-radius: 100%;
              border-radius: 100%;
              padding: 11px 11px 11px;

              .en-icon {
                width: 18px;
                height: 18px;
              }
            }

            .my-en-item-data-name {
              line-height: 40px;
              margin-left: 15px;
              font-size: 13px;
              font-weight: 400;
              color: #333333;
            }
          }

          .my-en-item-data-status {
            text-align: right;

            .my-en-item-data-date {
              color: #91A1B7;
              font-size: 12px;
              margin-bottom: 7px;
            }
          }
        }
      }

      .my-en-item-more {
        color: #91A1B7;
        font-size: 12px;
        cursor: pointer;
        padding: 13px 0 14px;
        background-color: #ffffff;
      }
    }
  }
</style>
