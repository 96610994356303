<!--
 * @Author: zhangjiaheng
 * @Date: 2020-06-30 15:41:47
 * @LastEditTime: 2022-08-25 17:42:54
 * @LastEditors: zhangjiaheng zhangjiaheng@enfry.com
 * @Description: 消息中心017类型的公告数据
 * @FilePath: \user\src\views\home\myEn\components\readComm\viewNotice.vue
-->
<template>
  <div>
    <en-dialog :visible="visiable" title="样式设置" :close-on-click-modal="false" @close="closeDialog" width="900px" style="text-aglin:left;" :append-to-body="true">
      <div class="box">
        <div class="top">
          <span class="tit">图表名称</span>
          <el-radio v-model="radio" label="1" style="margin-right:60px;">显示</el-radio>
          <el-radio v-model="radio" label="2">隐藏</el-radio>
        </div>
        <div class="bottom">图表样式</div>
        <div class="ui-form chart-allocationtype">
          <div class="img-box" :class="cur == '1' ? 'cur' : ''" style="margin-bottom: 10px;" @click="cur = '1'">
            <img class="cur1" src="@/assets/images/style1.png" />
            <img class="cur2" src="@/assets/images/style1-cur.png" />
          </div>
          <div class="img-box" :class="cur == '2' ? 'cur' : ''" style="margin-bottom: 10px;" @click="cur = '2'">
            <img class="cur1" src="@/assets/images/style2.png" />
            <img class="cur2" src="@/assets/images/style2-cur.png" />
          </div>
          <div class="img-box" :class="cur == '3' ? 'cur' : ''" @click="cur = '3'">
            <img class="cur1" src="@/assets/images/style3.png" />
            <img class="cur2" src="@/assets/images/style3-cur.png" />
          </div>
        </div>
      </div>
      <div slot="footer">
        <en-button @click="visiable = false">
          确定
        </en-button>
      </div>
    </en-dialog>
  </div>
</template>

<script>
export default {
  name: "viewNotice",
  props: {
    // 公共数据
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      visiable: true,
      radio: "1",
      cur: "1"
    };
  },
  mounted() {
    // this.data.customAttr;
    const customAttr = typeof this.data.data.customAttr === "string" ? JSON.parse((this.data.data.customAttr || "{}")) : this.data.data.customAttr;
    if (customAttr && customAttr.title) {
      this.radio = customAttr.title || "1";
      this.cur = customAttr.styleType || "1";
    } else {
      this.cur = this.data.data.refData.rspData.picData.styleType || "1";
    }
  },
  methods: {
    // 关闭回调
    closeDialog() {
      this.$emit("close", { title: this.radio, styleType: this.cur });
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  margin: 0 auto;
  width: 100%;
  min-height: 115px;
  // background: rgba(245, 248, 252, 1);
  // border: 1px solid rgba(232, 236, 242, 1);
  border-radius: 5px;
  padding: 19px 0 30px 0;
  font-size: 12px;
  line-height: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(99, 108, 120, 1);
  .top {
    margin-bottom: 20px;
  }
  .tit {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    // line-height: 26px;
    margin-right: 10px;
  }
  .bottom {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-bottom: 10px;
  }
  .img-box .cur2 {
    display: none;
  }
  .img-box .cur1 {
    display: block;
  }
  .img-box.cur .cur2 {
    display: block;
  }
  .img-box.cur .cur1 {
    display: none;
  }
}
</style>
