var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-define-group" },
    [
      _c("el-checkbox", {
        class: _vm.isDraggable ? "isDraggable" : "",
        on: { change: _vm.changeSelect },
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
      _c(
        "div",
        {
          staticClass: "bgContainer",
          class: _vm.isDraggable ? "isDraggable" : "",
        },
        [
          _c(
            "div",
            {
              staticClass: "container",
              class: _vm.classStyle,
              attrs: { draggable: "true" },
            },
            [
              _c(
                "div",
                { staticClass: "content", on: { click: _vm.changeOpen } },
                [
                  _c("div", { staticClass: "content-left" }, [
                    _c(
                      "div",
                      {
                        staticClass: "content-left-icon",
                        style: "background-color:" + _vm.color,
                      },
                      [_c("en-icon", { attrs: { name: _vm.icon } })],
                      1
                    ),
                    _c("div", { staticClass: "content-left-infos" }, [
                      _c("div", { staticClass: "content-left-infos-title" }, [
                        _vm._v(_vm._s(_vm.menuData.name)),
                      ]),
                      _c("div", { staticClass: "content-left-infos-detail" }, [
                        _vm._v(
                          _vm._s(_vm.menuData.description || _vm.detailText)
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _vm.showEdit
                        ? _c(
                            "el-dropdown",
                            {
                              staticClass: "content-edit",
                              style: {
                                "margin-right": _vm.hasChildren
                                  ? "40px"
                                  : "17px",
                              },
                              attrs: { "hide-on-click": false },
                            },
                            [
                              _c("en-icon", { attrs: { name: "bianji1" } }),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      staticClass: "drop-menus-custom",
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.startEdit.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  _vm.menuData.type == 1 ||
                                  _vm.menuData.type == 8
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          staticClass: "drop-menus-custom",
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.deleteData.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.hasChildren
                        ? _c("en-icon", {
                            staticClass: "content-arrow",
                            attrs: { name: _vm.open ? "zhankai" : "xiayiye" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm.hasChildren && _vm.open
                ? [
                    _vm.isKanban
                      ? _c(
                          "div",
                          { staticClass: "children kanban" },
                          [
                            _vm._l(
                              _vm.kanbanDataList,
                              function (item1, index1) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      key: index1,
                                      staticClass: "children-title",
                                    },
                                    [_vm._v(_vm._s(item1[0].refData.mdName))]
                                  ),
                                  _vm._l(item1, function (item2, index2) {
                                    return _c("menu-define-item", {
                                      key: index2,
                                      attrs: {
                                        draggable: false,
                                        "menu-data": item2,
                                      },
                                      on: { change: _vm.itemChange },
                                    })
                                  }),
                                ]
                              }
                            ),
                          ],
                          2
                        )
                      : _c("menu-subsetlist", {
                          attrs: {
                            menu: _vm.menuData,
                            configMenuDatas: _vm.configMenuDatas,
                            isKanBan: "",
                          },
                          on: { change: _vm.subSetListChange },
                        }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }