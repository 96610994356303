<template>
  <div class="normal-home">
    <div class="main-container">
      <grid-layout
        :layout.sync="dataList"
        :col-num="12"
        :row-height="80"
        :is-draggable="false"
        :is-resizable="false"
        :is-mirrored="false"
        :vertical-compact="true"
        :use-css-transforms="true"
        :margin="[8,8]"
      >
        <grid-item
          v-for="(item, index) in dataList"
          :key="index"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="index"
          :static="true"
        >
          <card-define
            :key="item.data.id + index"
            :menu-data="item.data"
            :classify-list="classifyList"
          >
          </card-define>
        </grid-item>
      </grid-layout>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import VueGridLayout from "vue-grid-layout";
import { classifyMenuData } from "@/api/homeMenu/handleMenu";
import { reportService } from "@/api/report";
import cardDefine from "../components/card-define";

export default {
  name: "NormalHome",
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    cardDefine
  },
  data() {
    return {
      classifyList: []
    };
  },
  mounted() {
    this.queryReportClassify();
    this.$nextTick(() => {
      setTimeout(() => {
        const doms = document.getElementsByClassName("static");
        if (doms.length) {
          for (let i = 0; i < doms.length; i++) {
            if (doms[i].style.transform.indexOf("(8px") !== -1) {
              doms[i].style.transform = doms[i].style.transform.replace("(8px", "(0px");
            }
            if (this.dataList[i].w === 12) {
              // eslint-disable-next-line radix
            doms[i].style.width = `${parseInt(doms[i].style.width.slice(0, doms[i].style.width.indexOf("px"))) + 16}px`;
            } else {
              // eslint-disable-next-line radix
            doms[i].style.width = `${parseInt(doms[i].style.width.slice(0, doms[i].style.width.indexOf("px"))) + 8}px`;
            }
          }
        }
      }, 0);
    });
  },
  methods: {
    async queryReportClassify() {
      const rsp = await reportService.queryReportClassify();
      this.classifyList = rsp;
      // console.log("queryReportClassify", rsp);
    }
  },
  computed: {
    menuList() {
      return this.$store.getters["main/menuList"];
    },
    dataList() {
      const homeData = this.menuList.filter((item) => item.type === 0)[0];
      const dataList = [];
      // console.log(homeData);
      // eslint-disable-next-line no-unused-vars
      let minY = null;
      homeData?.dataList?.forEach((item, index) => {
        const pointData = {
          x: 0,
          y: 0,
          w: 5,
          h: 5,
          i: String(index),
          data: item
        };
        const points = item.styleSize.split(",");
        if (points.length === 4) {
          pointData.x = Number(points[0]);
          pointData.y = Number(points[1]);
          pointData.w = Number(points[2]);
          pointData.h = Number(points[3]);
        }
        const menuData = classifyMenuData(item, this.menuList);
        if (menuData.dataList) {
          item.dataList = cloneDeep(menuData.dataList);
        }
        // console.log("minY", minY);
        if (!minY) {
          minY = pointData.y;
        } else {
          minY = Math.min(minY, pointData.y);
        }
        dataList.push(pointData);
      });
      if (minY && minY < 0) { // ？？？？？？为了解决第一个模块无法显示的问题加了个minY<0的判断
        dataList.forEach((item) => {
          if (item.y === minY) {
            item.y = 0;
          } else {
            item.y -= minY;
          }
        });
      }
      console.log(dataList, "总数据");
      return dataList;
    }
  }
};
</script>

<style lang="scss" scoped>
  .normal-home {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    // margin-left: -10px;
    // margin-top: -10px;
    position: relative;
    // padding-top: 10px;
    .main-container {
      &::-webkit-scrollbar {
        width: 0;
      }
      // width: calc(100% + 20px);
      // height: calc(100% + 20px);
      overflow-y: auto;
      padding: 0;
      .card-define {
        margin-top: 0;
        border-radius: 8px;
      }
      .cssTransforms{
        border-radius: 8px;
      }
    }
    /deep/.vxe-header--row .col--fixed {
      background-color: #f2f5f8 !important;
    }
  }
</style>
