<template>
  <div class="menu-define">
    <div class="cover">
    </div>
    <div class="content" v-if='!contentEdit'>
      <div class="content-header">
        <div class="title">
          自定义菜单
          <el-tooltip placement="bottom">
            <div slot="content" class='content-wrap'>
              <div style="line-height: 20px;">
                1.添加分类菜单：选择常用应用，组合生成个性化菜单
              </div>
              <div style="line-height: 20px;">
                2.菜单排序：拖动分类或应用，自由安排菜单排序
              </div>
            </div>
            <en-icon style="margin-left:5px"  name="tishi">
            </en-icon>
          </el-tooltip>
        </div>
        <en-button
          icon="guanbi-danchuang"
          type="text"
          color="#A9B5C5"
          @click="close">
        </en-button>
      </div>
      <div class="content-main">
        <el-dropdown   placement='bottom-start'   >
            <en-button icon="add" >添加</en-button>
            <el-dropdown-menu  class='left-2'  slot="dropdown">
              <el-dropdown-item   @click.native="openEditMenusDialog('created',1)">应用项</el-dropdown-item>
              <el-dropdown-item   @click.native="openEditMenusDialog('created',8)">外部链接</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <draggable
          v-model="configMenuDatas"
          @start="start"
          @end="end">
          <menu-define-group
            v-for="(item, index) in configMenuDatas"
            :key="index + item.id ? item.id : '0'"
            :menu-data="item"
            :configMenuDatas="configMenuDatas"
            :is-draggable="item === draggableData"
            chosenClass="chosen"
            @deleteData="deleteExitCustomData"
            @edit="editCustomData"
            @startEdit="startEditCustomData"
            @change='(data)=>{changeCustomData(index,data)}'
          ></menu-define-group>
        </draggable>
      </div>
      <div class="content-footer">
        <en-button @click="complete">确定</en-button>
      </div>
    </div>
    <div class='content edit-content' v-else>
      <div class="content-header">
        <div class="title">
          {{dialogTitle}}
        </div>
        <en-button
          icon="guanbi-danchuang"
          type="text"
          color="#A9B5C5"
          @click="closeEdit">
        </en-button>
      </div>
      <div class="content-main edit-content-main" ref="editContent">
        <el-form ref="menuForm" :model="editMenuForm" label-width="80px">
          <el-form-item label="选择图标" v-if="flgCustom">
            <icon-color-picker :colors='customColorList' :icons='customIconList' v-model="editMenuForm"  ></icon-color-picker>
          </el-form-item>
          <el-form-item label="菜单名称" prop="name" :rules="[
            { required: true, message: '请输入菜单名称', trigger: 'blur' },
          ]">
             <el-input class='width-50' v-model="editMenuForm.name" :readonly="!flgCustom"></el-input>
          </el-form-item>
          <el-form-item label="描述信息">
            <el-input class='width-50' type="textarea" :readonly="!flgCustom" v-model="editMenuForm.description"></el-input>
          </el-form-item>
          <el-form-item label="菜单内容" :rules="[
            { required: true, message: '请输入模板内容', trigger: 'blur' },
          ]">
            <menu-subsetlist :menu="editMenuForm" :configMenuDatas='configMenuDatas' @change='subSetListChange'></menu-subsetlist>
          </el-form-item>
        </el-form>
        <!-- 添加分类 -->
        <edit-classify-dialog
          :visible.sync="dialogClassifyVisible"
          :type='classEditType'
          :info='curClassIfy'
          :configMenuDatas='configMenuDatas'
          @complete='classifyComplete'></edit-classify-dialog>
        <!-- 添加应用项 -->
        <edit-app-dialog
          :visible.sync="appDialogVisible"
          :configMenuDatas='configMenuDatas'
          @complete='appComplete'></edit-app-dialog>
        <!-- 添加外部链接 -->
        <edit-link-dialog
          :visible.sync="linkDialogVisible"
          :init-data='editLink'
          @complete='linkComplete'></edit-link-dialog>
      </div>
      <div class="content-footer">
        <en-button @click="completeMenuEdit">确定</en-button>
      </div>
    </div>

  </div>
</template>

<script>

import draggable from "vuedraggable";
import { cloneDeep } from "lodash";
import { mapMutations } from "vuex";
import { request } from "en-js";
import API from "@/api/homeMenu";
import {
 menuClassifyTypeEnum
} from "@/api/homeMenu/handleMenu";
import menuDefineGroup from "./menu-define-group";
// import menuDefineItem from "./menu-define-item";
import iconColorPicker from "./icon-color-picker";
import editClassifyDialog from "./edit-classify-dialog";
import editAppDialog from "./edit-app-dialog";
import editLinkDialog from "./edit-link-dialog";
import menuSubsetlist from "./menu-subsetlist";
// import enIconText from "@/components/en-icon-text";

export default {
  name: "menuDefine",
  components: {
    menuDefineGroup,
    draggable,
    iconColorPicker,
    editClassifyDialog,
    editAppDialog,
    editLinkDialog,
    menuSubsetlist
  },
  props: {
    menuDatas: {
      type: Array
    }
  },
  data() {
    return {
      contentEdit: false,
      curDialogType: "created", // 当前菜单操作类型 created新增  update编辑
      dialogTitle: "添加菜单",
      dialogClassifyVisible: false,
      classEditType: "add", // 分类弹框编辑模式  add 新增   put 编辑
      curClassIfy: null, // 当前编辑的分类
      appDialogVisible: false,
      linkDialogVisible: false,
      editLink: {}, // 当前操作外链模块
      editMenuForm: {
        color: "#3e90fe",
        showIcon: "iconwendangguanli-xianxing",
        dataList: [],
        icon: "",
        isShow: 0,
        name: "",
        description: "",
        type: "",
        subsetList: [],
        classifyList: []
      },
      loading: false,
      customIconList: ["iconwendangguanli-xianxing", "zidingyifenlei2_2", "zidingyifenlei3_2", "zidingyifenlei4_2", "zidingyifenlei5_2", "zidingyifenlei6_2", "zidingyifenlei7_2", "zidingyifenlei8_2"],
      customColorList: ["#3e90fe", "#49BEF2", "#26C393", "#F7BF27", "#FFAD2C", "#F76B6B", "#D69E79", "#9367EB"],
      customMenuDatas: [],
      configMenuDatas: [],
      draggableData: null
    };
  },
  computed: {
    flgCustom() {
      return this.editMenuForm.type === 1 || this.editMenuForm.type === 8;
    },
    optionDatas() {
      const result = [];
      const datas = this.configMenuDatas.filter((item) => item.type !== 1 || item.type !== 8);
      datas.forEach((item) => {
        const itemData = { label: item.name, value: item.id, data: item };
        if (item?.dataList) {
          itemData.children = [];
          item.dataList.forEach((item1) => {
            const itemData1 = { label: item1.name, value: item1.refId, data: item1 };
            itemData.children.push(itemData1);
          });
        }
        result.push(itemData);
      });
      return result;
    }
  },
  methods: {
    ...mapMutations("main", [
      "setMenuData"
    ]),
    updateData() {
      const array = this.menuDatas.filter((item) => item.type !== 0 && item.type !== "");
      this.configMenuDatas = cloneDeep(array);
      this.configMenuDatas.forEach((item) => {
        // console.log(item, "updateData");
        this.initSecAppList(item);
      });
    },
    /**
     * 关闭自定义菜单页面，由父组件控制
     * */
    close() {
      this.$emit("close");
    },
    closeEdit() {
      this.contentEdit = false;
    },
    // 添加应用项模块弹框
    openEditAppDialog() {
      this.appDialogVisible = true;
    },
    // 添加外部链接弹框
    openEditLinkDialog() {
      this.editLink = {};
      this.linkDialogVisible = true;
    },
    /**
     * 自定义菜单数据完成后处理
     * @param data
     */
    @request(true, undefined, {
      subTitle: "正在处理..."
    })
    async completeAddCustomData(data) {
      const menuDatas = await API.saveCustomMenu([data]);
      // this.menuDatas = menuDatas;
      this.setMenuData(menuDatas);
      this.updateData();
    },
    @request(true, undefined, {
      subTitle: "正在删除..."
    })
    async deleteExitCustomData(data) {
      const menuDatas = await API.deleteCustomMenu(data.id);
      this.setMenuData(menuDatas);
      const index = this.configMenuDatas.indexOf(data);
      this.configMenuDatas.splice(index, 1);
    },
    @request(true, undefined, {
      subTitle: "正在处理..."
    })
    async editCustomData(data) {
      const menuDatas = await API.saveCustomMenu([data]);
      // this.menuDatas = menuDatas;
      this.setMenuData(menuDatas);
      this.$message("保存成功");
    },
    changeCustomData(index, data) {
      this.configMenuDatas[index] = data;
    },
    startEditCustomData(data) {
      console.log(data);
      this.editMenuForm = { ...this.editMenuForm, ...data };
      this.openEditMenusDialog("update", data.type);
    },
    addClassifyDialog(type, info) {
      if (type === "put") {
        this.curClassIfy = info;
      }
      this.dialogClassifyVisible = true;
      this.classEditType = type;
    },
    setCustomColor(color) {
      this.editMenuForm.color = color;
    },
    setCustomIcon(icon) {
      this.editMenuForm.showIcon = icon;
    },
    // 模块变动更新
    subSetListChange(data) {
      this.editMenuForm = cloneDeep(data);
    },
    // 删除菜单中的模块
    menuAppDelete(list, menu, ind) {
      const dataListInd = this.editMenuForm.dataList.findIndex((v) => v.refId === menu.refId);
      list.splice(ind, 1);
      console.log(dataListInd);
      this.editMenuForm.dataList.splice(dataListInd, 1);
    },
    // 编辑子菜单模块
    menuAppEdit(item, subIndex, index) {
      this.linkDialogVisible = true;
      this.editLink = {
       data: [item], subIndex, index, type: "edit"
      };
    },
    detailText(menuData) {
      const infos = {
        "2_12": "自定义看板",
        "2_1": "随时随地的便捷",
        "2_2": "看得见的OA，看不见的数据流动",
        "2_3": "运筹帷幄，明察秋毫",
        "2_6": "对象管理、接口操作、下载中心",
        "2_10": "业财数据自动翻译"
      };
      if (infos[menuData.icon]) {
        return infos[menuData.icon];
      }
      if (menuData.type === 3) {
        return "业务建模";
      }
      return "用户自定义";
    },
    initSecAppList(data) {
      if (data.dataList && data.dataList.length) {
        this.subsetList(data);
      }
    },
    // 处理菜单数据
    subsetList(data) {
        // 根据 存储的 二级分类
        const classifyList = cloneDeep(data.classifyList || []);
        // {name:"",id:"def",color:"",showIcon:"",refDataIds,refDataList:[]}
        const clonedataList = cloneDeep(data.dataList);
        // 循环 二级分类 处理数据
        classifyList.forEach((v) => {
            v.dataList = [];
            // 字符串变 数字
            v.refDataList = v.refDataIds.split(",");
            v.refDataList.forEach((vId) => {
             const classItem = clonedataList.find((item, ind) => {
               return item.refId === vId;
             });
             if (classItem && !classItem.referji) {
               v.dataList.push(classItem);
               classItem.referji = true;
             }
            });
        });

        const def = { name: "默认", id: "def", dataList: [] };
        def.dataList = clonedataList.filter((v) => !v.referji);

        classifyList.unshift(def);
        data.subsetList = classifyList;
    },
    // 编辑分类
    classifyComplete(data) {
      const classInfo = { ...data, refDataIds: "" };
      if (this.classEditType === "add") { // 新增
        this.editMenuForm.classifyList.push(classInfo);
      } else if (this.classEditType === "put") { // 编辑
        this.editMenuForm.classifyList.forEach((item) => {
          if (item.id === data.id || (item.classifyId === data.classifyId && !item.id && !data.id)) {
            item.showIcon = data.showIcon;
            item.color = data.color;
            item.name = data.name;
          }
        });
      }
      this.subsetList(this.editMenuForm);// 编辑完分类后 要同步更新子菜单subsetlist，  subsetlist字段仅用于本地管理 不提交后台
      this.dialogClassifyVisible = false;
    },
    // 添加外部链接
    linkComplete(outLinks, editInfo) {
      outLinks.forEach((item) => {
        item.isShow = 0;
        const refIndex = this.editMenuForm.dataList.findIndex((v) => v.refId === item.refId);// 检测外层是否有编辑项 refId相同表示有编辑项 则覆盖
        if (editInfo.type === "edit") {
          this.editMenuForm.dataList[refIndex] = item;
          this.editMenuForm.subsetList[editInfo.subIndex].dataList[editInfo.index] = item;
        } else {
          this.editMenuForm.dataList.push(item);
          this.editMenuForm.subsetList[0].dataList.push(item);
        }
      });
      this.editInfo = {};
      this.linkDialogVisible = false;
    },
    // 添加应用项回调
    appComplete(apps) {
      apps.forEach((item) => {
        item.isShow = 0;
        if (item.children || this.editMenuForm.dataList.find((v) => v.refId === item.value)) { // 避免重复和屏蔽一级菜单
          return;
        }
        this.editMenuForm.dataList.push(item.data);
        this.editMenuForm.subsetList[0].dataList.push(item.data);// 默认def子菜单要同步更新
      });
      this.appDialogVisible = false;
    },
    // 更新分类信息 用于监听本地subsetlist更新后 同步分类信息
    updateClassifyList() {
      this.editMenuForm.classifyList = this.editMenuForm.subsetList.filter((item) => item.id !== "def").map((v) => {
       const classIfy = { ...v };
       delete classIfy.refDataList;
       const ids = v.dataList.map((t) => t.refId);
       classIfy.refDataIds = Array.from(new Set(ids)).join();
       return classIfy;
      });
    },
    // 删除分类
    deleteClassIfy(classInfo, ind) {
      if (classInfo.dataList?.length) {
        console.log(1, this.editMenuForm.dataList);
        const defList = this.editMenuForm.subsetList.find((v) => v.id === "def");
        defList.dataList = defList.dataList.concat(classInfo.dataList);
      }
      this.editMenuForm.subsetList.splice(ind, 1);
      this.secDefEnd();
    },
    // 完成菜单编辑 确认
    completeMenuEdit() {
      // this.editMenuForm.subsetList = this.editMenuForm.subsetList || [{ name: "", id: "def", dataList: [] }];
      this.$refs.menuForm.validate((valid) => {
        if (valid) {
          if (this.curDialogType === "created") {
            this.completeAddCustomData(this.editMenuForm);
          } else if (this.flgCustom) {
            console.log(this.editMenuForm);
            this.editCustomData(this.editMenuForm);
          } else {
            this.configMenuDatas.forEach((item) => {
              if (item.id === this.editMenuForm.id) {
                item.classifyList = this.editMenuForm.classifyList;

                item.subsetList = this.editMenuForm.subsetList;
              }
            });
          }

          this.contentEdit = false;
          return "";
        }
        return false;
      });
      console.log("完成菜单编辑 确认", this.configMenuDatas, this.editMenuForm);
    },
    // 打开编辑菜单弹框
    openEditMenusDialog(dialogType, menuType) {
      this.curDialogType = dialogType;// 记录当前操作类型
      if (dialogType === "created") {
        this.editMenuForm = {
          color: "#3e90fe",
          showIcon: "iconwendangguanli-xianxing",
          dataList: [],
          icon: "1_1",
          isShow: 0,
          sort: this.setSort(this.optionDatas) - 1,
          name: "",
          description: "用户自定义",
          type: menuType,
          subsetList: [{ name: "", id: "def", dataList: [] }],
          classifyList: []
        };
      }
      this.editMenuForm.type = menuType;
      this.editMenuForm.description = this.detailText(this.editMenuForm);
      if (menuType === 1) {
        this.dialogTitle = `${dialogType === "created" ? "新增" : "编辑"}应用项`;
      } else if (menuType === 8) {
        this.dialogTitle = `${dialogType === "created" ? "新增" : "编辑"}外部链接`;
      } else {
        this.dialogTitle = "编辑菜单";
      }

      this.contentEdit = true;
      this.$nextTick(() => {
        this.$refs.editContent.scrollTop = 0;// 编辑框滚回顶部
      });
    },
    /**
     * 删除自定义数据
     * @param index
     */
    deleteCustomData(index) {
      this.customMenuDatas.splice(index, 1);
    },
    start(evt) {
      this.draggableData = this.configMenuDatas[evt.oldIndex];
    },
    start1(list, evt) {
      this.draggableData = list[evt.oldIndex];
    },
    end() {
      this.draggableData = null;
    },
    secEnd() {
      console.log(this.editMenuForm);
      this.end();
      this.updateClassifyList();
    },
    secDefEnd() {
      this.editMenuForm.dataList.sort((a, b) => {
        const aSort = this.editMenuForm.subsetList[0].dataList.findIndex((v) => v.refId === a.refId);
        const bSort = this.editMenuForm.subsetList[0].dataList.findIndex((v) => v.refId === b.refId);
        return aSort - bSort;
      });
      this.secEnd();
    },
    setSort(options) {
     const menuData = options.find((item) => item.data.customType !== menuClassifyTypeEnum.home
                                            && item.data.customType !== menuClassifyTypeEnum.myMatter
                                            && item.data.customType !== menuClassifyTypeEnum.helpCenter);
     console.log(menuData.data);
     return Number(menuData.data.sorts);
    },
    complete() {
      this.configMenuDatas.forEach((item, i) => { item.sorts = i + 1; });
      this.$emit("complete", this.configMenuDatas, [...this.configMenuDatas]);
    }
  },
  watch: {
    menuDatas: {
      handler() {
        this.updateData();
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-define {
  width: 100%;
  height: 100%;
  z-index: 999;
  position: absolute;
  .cover {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.5;
    background-color: #192F45;
    z-index: -1;
    padding: 0;
  }
  .width-50{
    width:50vw
  }
  .content {
    margin: 60px 8px 8px 8px;
    height: calc(100% - 60px - 8px);
    width: calc(100% - 8px - 8px);
    background-color: white;
    border-radius: 4px;
    .content-header {
      height: 50px;
      line-height: 50px;
      text-align: left;
      border-bottom:1px solid #E7EBF1;
      box-shadow:0px 3px 7px 0 rgba(0, 0, 0, 0.04);
      display:flex;
      justify-content:space-between;
      .title {
        display: flex;
        align-items: center;
        margin-left: 21px;
        margin-right: 5px;
        font-size:14px;
        font-weight:bold;
        color:rgba(51,51,51,1);
        .en-icon {
          width:16px;
          height:16px;
          color: #F7BF27;
        }
      }
      .content-wrap{
        font-size:12px;
      }
      .en-button {
        margin-right:20px;
      }
    }
    .content-main {
      text-align: left;
      overflow-y: auto;
      height: calc(100% - 50px - 63px);
      .en-button {
        margin-top: 20px;
        margin-left: 20px;
      }
    }
    .edit-content-main{
      padding-top:20px;
      .add-template{
        margin: 0;
      }
      .template-warp{
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
        border: 1px solid #ccc;
        min-height: 100px;
        margin: 0 20px;
        padding: 20px;
      }

    }
    .list-group{
      display: inline-block;
      margin-right: 20px;
      min-height: 50px;
      width: 100%;
      .list-group-item{
        margin-bottom: 20px;
      }
      .group-item-title{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .title{
          margin: 0 10px;
        }
      }
    }
    .content-footer {
      height: 63px;
      border-top:1px solid #E7EBF1;
      padding-right: 20px;
      line-height: 63px;
      text-align: right;
    }
    /deep/ .el-form-item__content{
      position: relative;
    }
    /deep/ .el-form-item__label{
      text-align: left;
    }
    /deep/ .el-form-item{
      padding-left: 21px;
    }
  }
}

</style>
