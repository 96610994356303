var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "menu-define" }, [
    _c("div", { staticClass: "cover" }),
    !_vm.contentEdit
      ? _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "content-header" },
            [
              _c(
                "div",
                { staticClass: "title" },
                [
                  _vm._v(" 自定义菜单 "),
                  _c(
                    "el-tooltip",
                    { attrs: { placement: "bottom" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "content-wrap",
                          attrs: { slot: "content" },
                          slot: "content",
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "line-height": "20px" } },
                            [
                              _vm._v(
                                " 1.添加分类菜单：选择常用应用，组合生成个性化菜单 "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticStyle: { "line-height": "20px" } },
                            [
                              _vm._v(
                                " 2.菜单排序：拖动分类或应用，自由安排菜单排序 "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("en-icon", {
                        staticStyle: { "margin-left": "5px" },
                        attrs: { name: "tishi" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("en-button", {
                attrs: {
                  icon: "guanbi-danchuang",
                  type: "text",
                  color: "#A9B5C5",
                },
                on: { click: _vm.close },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "content-main" },
            [
              _c(
                "el-dropdown",
                { attrs: { placement: "bottom-start" } },
                [
                  _c("en-button", { attrs: { icon: "add" } }, [_vm._v("添加")]),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "left-2",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown",
                    },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.openEditMenusDialog("created", 1)
                            },
                          },
                        },
                        [_vm._v("应用项")]
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.openEditMenusDialog("created", 8)
                            },
                          },
                        },
                        [_vm._v("外部链接")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "draggable",
                {
                  on: { start: _vm.start, end: _vm.end },
                  model: {
                    value: _vm.configMenuDatas,
                    callback: function ($$v) {
                      _vm.configMenuDatas = $$v
                    },
                    expression: "configMenuDatas",
                  },
                },
                _vm._l(_vm.configMenuDatas, function (item, index) {
                  return _c("menu-define-group", {
                    key: index + item.id ? item.id : "0",
                    attrs: {
                      "menu-data": item,
                      configMenuDatas: _vm.configMenuDatas,
                      "is-draggable": item === _vm.draggableData,
                      chosenClass: "chosen",
                    },
                    on: {
                      deleteData: _vm.deleteExitCustomData,
                      edit: _vm.editCustomData,
                      startEdit: _vm.startEditCustomData,
                      change: (data) => {
                        _vm.changeCustomData(index, data)
                      },
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "content-footer" },
            [
              _c("en-button", { on: { click: _vm.complete } }, [
                _vm._v("确定"),
              ]),
            ],
            1
          ),
        ])
      : _c("div", { staticClass: "content edit-content" }, [
          _c(
            "div",
            { staticClass: "content-header" },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(" " + _vm._s(_vm.dialogTitle) + " "),
              ]),
              _c("en-button", {
                attrs: {
                  icon: "guanbi-danchuang",
                  type: "text",
                  color: "#A9B5C5",
                },
                on: { click: _vm.closeEdit },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              ref: "editContent",
              staticClass: "content-main edit-content-main",
            },
            [
              _c(
                "el-form",
                {
                  ref: "menuForm",
                  attrs: { model: _vm.editMenuForm, "label-width": "80px" },
                },
                [
                  _vm.flgCustom
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "选择图标" } },
                        [
                          _c("icon-color-picker", {
                            attrs: {
                              colors: _vm.customColorList,
                              icons: _vm.customIconList,
                            },
                            model: {
                              value: _vm.editMenuForm,
                              callback: function ($$v) {
                                _vm.editMenuForm = $$v
                              },
                              expression: "editMenuForm",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "菜单名称",
                        prop: "name",
                        rules: [
                          {
                            required: true,
                            message: "请输入菜单名称",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "width-50",
                        attrs: { readonly: !_vm.flgCustom },
                        model: {
                          value: _vm.editMenuForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.editMenuForm, "name", $$v)
                          },
                          expression: "editMenuForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "描述信息" } },
                    [
                      _c("el-input", {
                        staticClass: "width-50",
                        attrs: { type: "textarea", readonly: !_vm.flgCustom },
                        model: {
                          value: _vm.editMenuForm.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.editMenuForm, "description", $$v)
                          },
                          expression: "editMenuForm.description",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "菜单内容",
                        rules: [
                          {
                            required: true,
                            message: "请输入模板内容",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("menu-subsetlist", {
                        attrs: {
                          menu: _vm.editMenuForm,
                          configMenuDatas: _vm.configMenuDatas,
                        },
                        on: { change: _vm.subSetListChange },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("edit-classify-dialog", {
                attrs: {
                  visible: _vm.dialogClassifyVisible,
                  type: _vm.classEditType,
                  info: _vm.curClassIfy,
                  configMenuDatas: _vm.configMenuDatas,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogClassifyVisible = $event
                  },
                  complete: _vm.classifyComplete,
                },
              }),
              _c("edit-app-dialog", {
                attrs: {
                  visible: _vm.appDialogVisible,
                  configMenuDatas: _vm.configMenuDatas,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.appDialogVisible = $event
                  },
                  complete: _vm.appComplete,
                },
              }),
              _c("edit-link-dialog", {
                attrs: {
                  visible: _vm.linkDialogVisible,
                  "init-data": _vm.editLink,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.linkDialogVisible = $event
                  },
                  complete: _vm.linkComplete,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "content-footer" },
            [
              _c("en-button", { on: { click: _vm.completeMenuEdit } }, [
                _vm._v("确定"),
              ]),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }