var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "home-classify-edit" }, [
    _c("div", { staticClass: "cover" }),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "content-header" },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.menuData.name) + " "),
          ]),
          _c("en-button", {
            attrs: { icon: "guanbi-danchuang", type: "text", color: "#A9B5C5" },
            on: { click: _vm.close },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "content-main" }, [
        _c(
          "div",
          { staticClass: "content-main-item" },
          _vm._l(_vm.dataList, function (item, index) {
            return _c("menu-define-item", {
              key: index,
              attrs: { "menu-data": item, "is-common": true },
            })
          }),
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "content-footer" },
        [_c("en-button", { on: { click: _vm.complete } }, [_vm._v("确定")])],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }