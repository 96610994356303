var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "template-warp-box" },
    [
      !_vm.isKanBan
        ? _c(
            "el-dropdown",
            { attrs: { placement: "bottom-start" } },
            [
              _c(
                "en-button",
                { staticClass: "add-template", attrs: { icon: "add" } },
                [_vm._v("添加")]
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.openClassifyDialog("add")
                        },
                      },
                    },
                    [_vm._v("分类")]
                  ),
                  _vm.menuData.type === 1
                    ? _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.openEditAppDialog.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v("应用项")]
                      )
                    : _vm._e(),
                  _vm.menuData.type === 8
                    ? _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.openEditLinkDialog.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v("外部链接")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { class: { "template-warp": !_vm.isKanBan, isKanBan: _vm.isKanBan } },
        [
          _c(
            "draggable",
            {
              staticClass: "list-group",
              attrs: {
                list: _vm.menuData.subsetList[0].dataList,
                group: "sec",
              },
              on: {
                start: (evt) => {
                  _vm.start(_vm.menuData.subsetList[0].dataList, evt)
                },
                end: _vm.end,
              },
            },
            _vm._l(_vm.menuData.subsetList[0].dataList, function (item, index) {
              return _c("menu-define-item", {
                key: index,
                attrs: {
                  "check-box": _vm.isKanBan,
                  draggable: true,
                  "menu-data": item,
                  "parent-type": _vm.menuData.type,
                  "is-draggable": _vm.draggableData === item,
                },
                on: {
                  delete: function ($event) {
                    return _vm.menuAppDelete(
                      _vm.menuData.subsetList[0].dataList,
                      item,
                      index
                    )
                  },
                  edit: function ($event) {
                    return _vm.menuAppEdit(item, 0, index)
                  },
                },
              })
            }),
            1
          ),
          _c(
            "draggable",
            {
              staticClass: "list-group",
              attrs: { list: _vm.menuData.subsetList, group: "first" },
            },
            _vm._l(_vm.menuData.subsetList, function (classIfyItem, index0) {
              return _c(
                "div",
                { key: index0, staticClass: "list-group-item" },
                [
                  index0
                    ? [
                        classIfyItem.id !== "def"
                          ? _c(
                              "div",
                              { staticClass: "group-item-title" },
                              [
                                _c("en-icon", {
                                  attrs: {
                                    name:
                                      "erjifenlei" +
                                      classIfyItem.showIcon +
                                      "_1",
                                    size: "18px",
                                    color: classIfyItem.color,
                                  },
                                }),
                                _c("span", { staticClass: "title" }, [
                                  _vm._v(_vm._s(classIfyItem.name)),
                                ]),
                                !_vm.isKanBan
                                  ? _c(
                                      "el-dropdown",
                                      { attrs: { placement: "bottom-start" } },
                                      [
                                        _c("en-icon", {
                                          staticStyle: { color: "#bbb" },
                                          attrs: {
                                            name: "gengduo-liebiaoyouce",
                                            size: "18px",
                                          },
                                        }),
                                        _c(
                                          "el-dropdown-menu",
                                          {
                                            attrs: { slot: "dropdown" },
                                            slot: "dropdown",
                                          },
                                          [
                                            _c(
                                              "el-dropdown-item",
                                              [
                                                _c("en-icon-text", {
                                                  attrs: {
                                                    name: "bianji",
                                                    size: "16px",
                                                    color: "#26c494",
                                                    label: "编辑",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.openClassifyDialog(
                                                        "put",
                                                        classIfyItem
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-dropdown-item",
                                              [
                                                _c("en-icon-text", {
                                                  attrs: {
                                                    name: "shanchu",
                                                    size: "16px",
                                                    color: "#f36e6b",
                                                    label: "删除",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.deleteClassIfy(
                                                        classIfyItem,
                                                        index0
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "draggable",
                          {
                            key: index0,
                            staticClass: "list-group",
                            attrs: {
                              list: classIfyItem.dataList,
                              group: "sec",
                            },
                            on: {
                              start: (evt) => {
                                _vm.start(classIfyItem.dataList, evt)
                              },
                              end: _vm.end,
                            },
                          },
                          _vm._l(classIfyItem.dataList, function (item, index) {
                            return _c("menu-define-item", {
                              key: index,
                              attrs: {
                                "check-box": _vm.isKanBan,
                                draggableFlg: true,
                                "menu-data": item,
                                "parent-type": _vm.menuData.type,
                                "is-draggable": _vm.draggableData === item,
                              },
                              on: {
                                delete: function ($event) {
                                  return _vm.menuAppDelete(
                                    classIfyItem.dataList,
                                    item,
                                    index
                                  )
                                },
                                edit: function ($event) {
                                  return _vm.menuAppEdit(item, index0, index)
                                },
                              },
                            })
                          }),
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            0
          ),
        ],
        1
      ),
      _c("edit-classify-dialog", {
        attrs: {
          visible: _vm.dialogClassifyVisible,
          type: _vm.classEditType,
          info: _vm.curClassIfy,
          configMenuDatas: _vm.configMenuDatas,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogClassifyVisible = $event
          },
          complete: _vm.classifyComplete,
        },
      }),
      _c("edit-app-dialog", {
        attrs: {
          visible: _vm.appDialogVisible,
          configMenuDatas: _vm.configMenuDatas,
        },
        on: {
          "update:visible": function ($event) {
            _vm.appDialogVisible = $event
          },
          complete: _vm.appComplete,
        },
      }),
      _c("edit-link-dialog", {
        attrs: { visible: _vm.linkDialogVisible, "init-data": _vm.editLink },
        on: {
          "update:visible": function ($event) {
            _vm.linkDialogVisible = $event
          },
          complete: _vm.linkComplete,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }