<template>
  <div class="home-classify-edit">
    <div class="cover">
    </div>
    <div class="content">
      <div class="content-header">
        <div class="title">
          {{ menuData.name }}
        </div>
        <en-button
          icon="guanbi-danchuang"
          type="text"
          color="#A9B5C5"
          @click="close">
        </en-button>
      </div>
      <div class="content-main">
        <div class="content-main-item">
          <menu-define-item
            v-for="(item, index) in dataList"
            :key="index"
            :menu-data="item"
            :is-common="true"
          >
          </menu-define-item>
        </div>
      </div>
      <div class="content-footer">
        <en-button @click="complete">确定</en-button>
      </div>
    </div>
  </div>
</template>

<script>

import { cloneDeep } from "lodash";

import menuDefineItem from "./menu-define-item";

export default {
  name: "home-classify-edit",
  components: {
    menuDefineItem
  },
  props: {
    menuData: {
      type: Object
    }
  },
  data() {
    return {
      dataList: []
    };
  },
  created() {
    this.dataList = cloneDeep(this.menuData.dataList);
  },
  methods: {
    /**
       * 关闭自定义菜单页面，由父组件控制
       * */
    close() {
      this.$emit("close");
    },
    complete() {
      const selectLen = this.dataList.filter((item) => item.isCommon === "000").length;
      if (selectLen === 0) {
        this.$message("至少勾选一项！");
        return;
      }
      if (selectLen > 8) {
        this.$message("最多勾选八项！");
        return;
      }
      this.$emit("complete", this.dataList);
    }
  }
};
</script>

<style lang="scss" scoped>
  .home-classify-edit {
    width: 100%;
    height: 100%;
    z-index: 999;
    position: absolute;
    .cover {
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0.5;
      background-color: #192F45;
      z-index: -1;
      padding: 0;
    }
    .content {
      margin: 60px 8px 8px 8px;
      height: calc(100% - 60px - 8px);
      width: calc(100% - 8px - 8px);
      background-color: white;
      border-radius: 4px;
      .content-main {
        text-align: left;
        overflow-y: auto;
        height: calc(100% - 50px - 63px);
        .en-button {
          margin-top: 20px;
          margin-left: 20px;
        }
      }
      .content-main-item {
        background: #F5F7FA;
        border:1px solid #A2CDF7;
        // box-shadow:0 4px 6px 0 #2F789A;
        border-radius:4px;
        margin: 20px;
        padding: 20px;
      }
      .content-header {
        height: 50px;
        line-height: 50px;
        text-align: left;
        border-bottom:1px solid #E7EBF1;
        box-shadow:0px 3px 7px 0 rgba(47,120,154,0.15);
        display:flex;
        justify-content:space-between;
        .title {
          display: inline-block;
          margin-left: 21px;
          margin-right: 5px;
          font-size:14px;
          font-weight:bold;
          color:rgba(51,51,51,1);
          .en-icon {
            width:16px;
            height:16px;
            color: #F7BF27;
          }
        }
        .en-button {
          margin-right: 14px;
        }
      }
      .content-footer {
        height: 63px;
        border-top:1px solid #E7EBF1;
        padding-right: 20px;
        line-height: 63px;
        text-align: right;
      }
    }
  }

</style>
