var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "en-dialog",
        {
          staticStyle: { "text-aglin": "left" },
          attrs: {
            visible: _vm.visiable,
            title: "样式设置",
            "close-on-click-modal": false,
            width: "900px",
            "append-to-body": true,
          },
          on: { close: _vm.closeDialog },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "80px" } },
            [
              _c(
                "el-collapse",
                {
                  on: { change: _vm.handleChange },
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { name: "1" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c(
                          "span",
                          { staticClass: "iconfont title-icon-style titicon" },
                          [_vm._v(" ")]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "collapse-title" }, [
                          _vm._v("标题样式"),
                        ]),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "标题名称" } },
                        [
                          _c("el-input", {
                            staticStyle: { float: "left", width: "600px" },
                            attrs: { disabled: ![1].includes(_vm.data.type) },
                            model: {
                              value: _vm.data.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "name", $$v)
                              },
                              expression: "data.name",
                            },
                          }),
                          _c(
                            "el-checkbox",
                            {
                              staticStyle: {
                                float: "left",
                                "margin-left": "15px",
                              },
                              model: {
                                value: _vm.headerStyleData.showName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.headerStyleData, "showName", $$v)
                                },
                                expression: "headerStyleData.showName",
                              },
                            },
                            [_vm._v("隐藏")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "标题文字" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { float: "left", width: "600px" },
                              attrs: { placeholder: "请选择标题文字" },
                              model: {
                                value: _vm.headerStyleData.titleText.fontSize,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.headerStyleData.titleText,
                                    "fontSize",
                                    $$v
                                  )
                                },
                                expression:
                                  "headerStyleData.titleText.fontSize",
                              },
                            },
                            _vm._l(_vm.range(14, 29, 2), function (num) {
                              return _c("el-option", {
                                key: num,
                                attrs: { value: num + "px", label: num + "px" },
                              })
                            }),
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "titleText",
                              style: {
                                borderColor: _vm.headerStyleData.titleText
                                  .isWeight
                                  ? "#3370FF"
                                  : "#ccc",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.headerStyleData.titleText.isWeight =
                                    !_vm.headerStyleData.titleText.isWeight
                                },
                              },
                            },
                            [
                              _c("en-icon", {
                                attrs: {
                                  name: "wenzijiacu",
                                  size: "12px",
                                  color: _vm.headerStyleData.titleText.isWeight
                                    ? "#3370FF"
                                    : "#ccc",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "titleText" },
                            [
                              _c(
                                "el-dropdown",
                                {
                                  attrs: { trigger: "click" },
                                  on: { command: _vm.titleTextAligen },
                                },
                                [
                                  _c("en-icon", {
                                    attrs: {
                                      name: _vm.headerStyleData.titleText
                                        .alignment,
                                      size: "12px",
                                    },
                                  }),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "iconzuoduiqi1" } },
                                        [
                                          _c("en-icon", {
                                            attrs: {
                                              name: "iconzuoduiqi1",
                                              size: "12px",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                                "margin-left": "10px",
                                              },
                                            },
                                            [_vm._v("左对齐")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "juzhongduiqi1" } },
                                        [
                                          _c("en-icon", {
                                            attrs: {
                                              name: "juzhongduiqi1",
                                              size: "12px",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                                "margin-left": "10px",
                                              },
                                            },
                                            [_vm._v("居中对齐")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "iconyouduiqi1" } },
                                        [
                                          _c("en-icon", {
                                            attrs: {
                                              name: "iconyouduiqi1",
                                              size: "12px",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                                "margin-left": "10px",
                                              },
                                            },
                                            [_vm._v("右对齐")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "titleTextColor" },
                            [
                              _c("el-color-picker", {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.headerStyleData.titleText.color,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.headerStyleData.titleText,
                                      "color",
                                      $$v
                                    )
                                  },
                                  expression: "headerStyleData.titleText.color",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "标题样式" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { float: "left", width: "600px" },
                              attrs: { placeholder: "请选择标题文字" },
                              model: {
                                value: _vm.headerStyleData.titleStyle,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.headerStyleData,
                                    "titleStyle",
                                    $$v
                                  )
                                },
                                expression: "headerStyleData.titleStyle",
                              },
                            },
                            _vm._l(_vm.titleStyleList, function (item, index) {
                              return _c(
                                "el-option",
                                {
                                  key: index,
                                  attrs: {
                                    value: item.value,
                                    label: item.name,
                                  },
                                },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "right-end",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "titleStyle" }, [
                                        _c("img", {
                                          staticClass: "styleDemoImg",
                                          attrs: { src: item.imgSrc, alt: "" },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "line-height": "32px",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.desc))]
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-form-item", { attrs: { label: "图标颜色" } }, [
                        _c(
                          "div",
                          { staticClass: "color-box" },
                          [
                            _c(
                              "span",
                              { staticClass: "iconfont title-icon-style" },
                              [_vm._v("")]
                            ),
                            _c("el-color-picker", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.headerStyleData.titleStyle.iconColor,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.headerStyleData.titleStyle,
                                    "iconColor",
                                    $$v
                                  )
                                },
                                expression:
                                  "headerStyleData.titleStyle.iconColor",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                  !_vm.isChild
                    ? _c(
                        "el-collapse-item",
                        { attrs: { name: "2" } },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "iconfont title-icon-style echarticon",
                              },
                              [_vm._v(" ")]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "collapse-title" }, [
                              _vm._v("图表样式"),
                            ]),
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { label: "选择样式" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    float: "left",
                                    width: "600px",
                                  },
                                  attrs: { placeholder: "选择样式" },
                                  on: { change: _vm.chartStyleChange },
                                  model: {
                                    value: _vm.headerStyleData.chartStyle,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.headerStyleData,
                                        "chartStyle",
                                        $$v
                                      )
                                    },
                                    expression: "headerStyleData.chartStyle",
                                  },
                                },
                                _vm._l(
                                  _vm.iconStyleList,
                                  function (item, index) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: index,
                                        attrs: {
                                          value: index,
                                          label: item.name,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "right-end",
                                              trigger: "hover",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "titleStyle" },
                                              [
                                                _c("img", {
                                                  staticClass:
                                                    "iconstyleDemoImg",
                                                  attrs: {
                                                    src: item.imgSrc,
                                                    alt: "",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "line-height": "32px",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.desc))]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "reference" },
                                                slot: "reference",
                                              },
                                              [_vm._v(_vm._s(item.name))]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.headerStyleData.chartStyle == 6
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "卡片样式" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        float: "left",
                                        width: "600px",
                                      },
                                      attrs: { placeholder: "请选择卡片样式" },
                                      model: {
                                        value: _vm.headerStyleData.cartBg.style,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.headerStyleData.cartBg,
                                            "style",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "headerStyleData.cartBg.style",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-option",
                                        {
                                          attrs: {
                                            label: "渐色渐变卡片组",
                                            value: "1",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "colorImgbg",
                                            attrs: {
                                              src: require("./../assets/images/jianbianzu6-2.png"),
                                              alt: "",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                              },
                                            },
                                            [_vm._v("渐色渐变卡片组")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { float: "left" } },
                                    [
                                      _c("my-color-picker", {
                                        attrs: { typeSelections: 2 },
                                        model: {
                                          value:
                                            _vm.headerStyleData.cartBg.color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.headerStyleData.cartBg,
                                              "color",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "headerStyleData.cartBg.color",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            { attrs: { label: "模板文字" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    float: "left",
                                    width: "600px",
                                  },
                                  attrs: { placeholder: "请选择模板文字" },
                                  model: {
                                    value:
                                      _vm.headerStyleData.templateText.fontSize,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.headerStyleData.templateText,
                                        "fontSize",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "headerStyleData.templateText.fontSize",
                                  },
                                },
                                _vm._l(_vm.range(14, 29, 2), function (num) {
                                  return _c("el-option", {
                                    key: num,
                                    attrs: {
                                      value: num + "px",
                                      label: num + "px",
                                    },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "titleText",
                                  style: {
                                    borderColor: _vm.headerStyleData
                                      .templateText.isWeight
                                      ? "#3370FF"
                                      : "#ccc",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.headerStyleData.templateText.isWeight =
                                        !_vm.headerStyleData.templateText
                                          .isWeight
                                    },
                                  },
                                },
                                [
                                  _c("en-icon", {
                                    attrs: {
                                      name: "wenzijiacu",
                                      size: "12px",
                                      color: _vm.headerStyleData.templateText
                                        .isWeight
                                        ? "#3370FF"
                                        : "#ccc",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "titleTextColor" },
                                [
                                  _c("el-color-picker", {
                                    attrs: { size: "small" },
                                    model: {
                                      value:
                                        _vm.headerStyleData.templateText.color,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.headerStyleData.templateText,
                                          "color",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "headerStyleData.templateText.color",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "统计数" } },
                            [
                              _c("el-switch", {
                                model: {
                                  value: _vm.headerStyleData.statisticsNum,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.headerStyleData,
                                      "statisticsNum",
                                      $$v
                                    )
                                  },
                                  expression: "headerStyleData.statisticsNum",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "统计文字" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    float: "left",
                                    width: "600px",
                                  },
                                  attrs: { placeholder: "请选择统计文字" },
                                  model: {
                                    value:
                                      _vm.headerStyleData.statisticsText
                                        .fontSize,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.headerStyleData.statisticsText,
                                        "fontSize",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "headerStyleData.statisticsText.fontSize",
                                  },
                                },
                                _vm._l(_vm.range(14, 29, 2), function (num) {
                                  return _c("el-option", {
                                    key: num,
                                    attrs: {
                                      value: num + "px",
                                      label: num + "px",
                                    },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "titleText",
                                  style: {
                                    borderColor: _vm.headerStyleData
                                      .statisticsText.isWeight
                                      ? "#3370FF"
                                      : "#ccc",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.headerStyleData.statisticsText.isWeight =
                                        !_vm.headerStyleData.statisticsText
                                          .isWeight
                                    },
                                  },
                                },
                                [
                                  _c("en-icon", {
                                    attrs: {
                                      name: "wenzijiacu",
                                      size: "12px",
                                      color: _vm.headerStyleData.statisticsText
                                        .isWeight
                                        ? "#3370FF"
                                        : "#ccc",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "titleTextColor" },
                                [
                                  _c("el-color-picker", {
                                    attrs: { size: "small" },
                                    model: {
                                      value:
                                        _vm.headerStyleData.templateText.color,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.headerStyleData.templateText,
                                          "color",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "headerStyleData.templateText.color",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.headerStyleData.chartStyle != 0
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "对齐方式" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        float: "left",
                                        width: "600px",
                                      },
                                      attrs: { placeholder: "请选择对齐方式" },
                                      model: {
                                        value: _vm.headerStyleData.alignment,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.headerStyleData,
                                            "alignment",
                                            $$v
                                          )
                                        },
                                        expression: "headerStyleData.alignment",
                                      },
                                    },
                                    [
                                      _vm.headerStyleData.chartStyle == 2 ||
                                      _vm.headerStyleData.chartStyle == 5
                                        ? _c("el-option", {
                                            attrs: {
                                              label: "图标居文字上方",
                                              value: "1",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.headerStyleData.chartStyle == 2 ||
                                      _vm.headerStyleData.chartStyle == 5
                                        ? _c("el-option", {
                                            attrs: {
                                              label: "图标居文字下方",
                                              value: "2",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.headerStyleData.chartStyle == 1 ||
                                      _vm.headerStyleData.chartStyle == 3 ||
                                      _vm.headerStyleData.chartStyle == 4 ||
                                      _vm.headerStyleData.chartStyle == 6
                                        ? _c("el-option", {
                                            attrs: {
                                              label: "图标居文字左方",
                                              value: "3",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.headerStyleData.chartStyle == 1 ||
                                      _vm.headerStyleData.chartStyle == 3 ||
                                      _vm.headerStyleData.chartStyle == 4 ||
                                      _vm.headerStyleData.chartStyle == 6
                                        ? _c("el-option", {
                                            attrs: {
                                              label: "图标居文字右方",
                                              value: "4",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            { attrs: { label: "文字排序" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    float: "left",
                                    width: "600px",
                                  },
                                  attrs: { placeholder: "请选择文字排序" },
                                  model: {
                                    value: _vm.headerStyleData.textSorting,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.headerStyleData,
                                        "textSorting",
                                        $$v
                                      )
                                    },
                                    expression: "headerStyleData.textSorting",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: "模板名称居上",
                                      value: "1",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "统计数居上", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "文字对齐" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  staticStyle: { "margin-bottom": "30px" },
                                  model: {
                                    value: _vm.headerStyleData.textAlignment,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.headerStyleData,
                                        "textAlignment",
                                        $$v
                                      )
                                    },
                                    expression: "headerStyleData.textAlignment",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "left", value: "left" } },
                                    [_vm._v("左对齐")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    {
                                      attrs: {
                                        label: "center",
                                        value: "center",
                                      },
                                    },
                                    [_vm._v("居中对齐")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    {
                                      attrs: { label: "right", value: "right" },
                                    },
                                    [_vm._v("右对齐")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.headerStyleData.chartStyle == 6 ||
                          _vm.headerStyleData.chartStyle == 8
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "边框设置" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        float: "left",
                                        width: "300px",
                                      },
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value:
                                          _vm.headerStyleData.borderStyle.style,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.headerStyleData.borderStyle,
                                            "style",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "headerStyleData.borderStyle.style",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "无", value: "0" },
                                      }),
                                      _c(
                                        "el-option",
                                        {
                                          attrs: { label: "实线", value: "1" },
                                        },
                                        [
                                          _c("span", [_vm._v("实线")]),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                display: "inline-block",
                                                width: "200px",
                                                "margin-left": "20px",
                                              },
                                            },
                                            [_c("hr")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-option",
                                        {
                                          attrs: { label: "虚线", value: "2" },
                                        },
                                        [
                                          _c("span", [_vm._v("虚线")]),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                display: "inline-block",
                                                width: "200px",
                                                "margin-left": "20px",
                                              },
                                            },
                                            [_vm._v("---------------------")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-option",
                                        {
                                          attrs: { label: "粗线", value: "3" },
                                        },
                                        [
                                          _c("span", [_vm._v("粗线")]),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                display: "inline-block",
                                                width: "200px",
                                                "margin-left": "20px",
                                              },
                                            },
                                            [
                                              _c("hr", {
                                                staticStyle: {
                                                  height: "3px",
                                                  background: "#000",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        float: "left",
                                        width: "300px",
                                      },
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value:
                                          _vm.headerStyleData.borderStyle.size,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.headerStyleData.borderStyle,
                                            "size",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "headerStyleData.borderStyle.size",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "1px", value: "1px" },
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "2px", value: "2px" },
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "3px", value: "3px" },
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "4px", value: "4px" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "titleTextColor" },
                                    [
                                      _c("el-color-picker", {
                                        attrs: { size: "small" },
                                        model: {
                                          value:
                                            _vm.headerStyleData.borderStyle
                                              .color,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.headerStyleData.borderStyle,
                                              "color",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "headerStyleData.borderStyle.color",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "el-collapse-item",
                    { attrs: { name: "3" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c(
                          "span",
                          { staticClass: "iconfont title-icon-style bgicon" },
                          [_vm._v("")]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "collapse-title" }, [
                          _vm._v("背景样式"),
                        ]),
                      ]),
                      _c("el-form-item", { attrs: { label: "背景底色" } }, [
                        _c(
                          "div",
                          { staticClass: "color-box" },
                          [
                            _c(
                              "span",
                              { staticClass: "iconfont title-icon-style" },
                              [_vm._v("")]
                            ),
                            _c("el-color-picker", {
                              attrs: { size: "small" },
                              on: { change: _vm.bgStyleChange },
                              model: {
                                value: _vm.headerStyleData.bgStyle,
                                callback: function ($$v) {
                                  _vm.$set(_vm.headerStyleData, "bgStyle", $$v)
                                },
                                expression: "headerStyleData.bgStyle",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("en-button", { on: { click: _vm.closeDialog } }, [
                _vm._v(" 确定 "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }