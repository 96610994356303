var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-define-item" },
    [
      _vm.checkBox
        ? _c("el-checkbox", {
            staticClass: "check-left",
            class: _vm.isDraggable ? "isDraggable" : "",
            on: { change: _vm.change },
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "bgContent",
          class: _vm.isDraggable ? "isDraggable" : "",
          attrs: { draggable: _vm.draggableFlg },
        },
        [
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              {
                staticClass: "content-icon",
                style: "background-color:" + _vm.color,
              },
              [_c("en-icon", { attrs: { name: _vm.icon } })],
              1
            ),
            _c("div", { staticClass: "content-text" }, [
              _vm._v(_vm._s(_vm.menuData.name)),
            ]),
          ]),
          _vm.flgCustom && !_vm.checkBox
            ? _c(
                "el-dropdown",
                {
                  staticClass: "right-edit",
                  attrs: { placement: "bottom-start" },
                },
                [
                  _vm.flgCustom
                    ? _c("en-icon", {
                        staticStyle: { color: "#bbb" },
                        attrs: { name: "gengduo-liebiaoyouce", size: "18px" },
                      })
                    : _vm._e(),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _vm.parentType == 8
                        ? _c(
                            "el-dropdown-item",
                            [
                              _c("en-icon-text", {
                                attrs: {
                                  name: "bianji",
                                  size: "14px",
                                  color: "#26c494",
                                  label: "编辑",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.editItem.apply(null, arguments)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-dropdown-item",
                        [
                          _c("en-icon-text", {
                            attrs: {
                              name: "shanchu",
                              size: "14px",
                              color: "#f36e6b",
                              label: "删除",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.deleteItem.apply(null, arguments)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }