<!--
 * @Author: zhangjiaheng
 * @Date: 2020-06-30 15:41:47
 * @LastEditTime: 2024-03-04 11:44:13
 * @LastEditors: Please set LastEditors
 * @Description: 消息中心017类型的公告数据
 * @FilePath: \user\src\views\home\myEn\components\readComm\viewNotice.vue
-->
<template>
  <div>
    <en-dialog
      :visible="visiable"
      title="样式设置"
      :close-on-click-modal="false"
      @close="closeDialog"
      width="900px"
      style="text-aglin: left"
      :append-to-body="true"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item name="1">
            <template slot="title">
                <span class="iconfont title-icon-style titicon">&#xe809; </span> <span class="collapse-title">标题样式</span>
            </template>
            <el-form-item label="标题名称">
              <el-input
                v-model="data.name"
                :disabled="![1].includes(data.type)"
                style="float: left; width: 600px"
              ></el-input>
              <el-checkbox
                style="float: left; margin-left: 15px"
                v-model="headerStyleData.showName"
                >隐藏</el-checkbox
              >
            </el-form-item>
            <el-form-item label="标题文字">
              <el-select
                placeholder="请选择标题文字"
                v-model="headerStyleData.titleText.fontSize"
                style="float: left; width: 600px"
              >
                <el-option
                  v-for="num in range(14, 29, 2)"
                  :key="num"
                  :value="num + 'px'"
                  :label="num + 'px'"
                >
                </el-option>
              </el-select>
              <div
                class="titleText"
                :style="{
                  borderColor: headerStyleData.titleText.isWeight
                    ? '#3370FF'
                    : '#ccc',
                }"
                @click="
                  headerStyleData.titleText.isWeight =
                    !headerStyleData.titleText.isWeight
                "
              >
                <en-icon
                  name="wenzijiacu"
                  size="12px"
                  :color="
                    headerStyleData.titleText.isWeight ? '#3370FF' : '#ccc'
                  "
                ></en-icon>
              </div>
              <div class="titleText">
                <el-dropdown @command="titleTextAligen" trigger="click">
                  <en-icon
                    :name="headerStyleData.titleText.alignment"
                    size="12px"
                  >
                  </en-icon>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="iconzuoduiqi1">
                      <en-icon name="iconzuoduiqi1" size="12px"> </en-icon>
                      <span style="font-size: 14px; margin-left: 10px"
                        >左对齐</span
                      >
                    </el-dropdown-item>
                    <el-dropdown-item command="juzhongduiqi1">
                      <en-icon name="juzhongduiqi1" size="12px"> </en-icon>
                      <span style="font-size: 14px; margin-left: 10px"
                        >居中对齐</span
                      >
                    </el-dropdown-item>
                    <el-dropdown-item command="iconyouduiqi1">
                      <en-icon name="iconyouduiqi1" size="12px"> </en-icon>
                      <span style="font-size: 14px; margin-left: 10px"
                        >右对齐</span
                      >
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="titleTextColor">
                <el-color-picker
                  v-model="headerStyleData.titleText.color"
                  size="small"
                ></el-color-picker>
              </div>
            </el-form-item>
            <el-form-item label="标题样式">
              <el-select
                placeholder="请选择标题文字"
                v-model="headerStyleData.titleStyle"
                style="float: left; width: 600px"
              >
                <el-option
                  v-for="(item, index) in titleStyleList"
                  :key="index"
                  :value="item.value"
                  :label="item.name"
                >
                  <el-popover placement="right-end" trigger="hover">
                    <div class="titleStyle">
                      <img class="styleDemoImg" :src="item.imgSrc" alt="" />
                      <span style="line-height: 32px">{{ item.desc }}</span>
                    </div>
                    <div slot="reference">{{ item.name }}</div>
                  </el-popover>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="图标颜色">
              <div class="color-box">
                <span class="iconfont title-icon-style">&#xec8f;</span>
                <el-color-picker
                  v-model="headerStyleData.titleStyle.iconColor"
                  size="small"
                ></el-color-picker>
              </div>
            </el-form-item>
          </el-collapse-item>
          <el-collapse-item name="2" v-if="!isChild">
            <template slot="title">
                <span class="iconfont title-icon-style echarticon">&#xe808; </span> <span class="collapse-title">图表样式</span>
            </template>
            <el-form-item label="选择样式">
              <el-select
                placeholder="选择样式"
                v-model="headerStyleData.chartStyle"
                @change="chartStyleChange"
                style="float: left; width: 600px"
              >
                <el-option
                  v-for="(item, index) in iconStyleList"
                  :key="index"
                  :value="index"
                  :label="item.name"
                >
                  <el-popover placement="right-end" trigger="hover">
                    <div class="titleStyle">
                      <img class="iconstyleDemoImg" :src="item.imgSrc" alt="" />
                      <span style="line-height: 32px">{{ item.desc }}</span>
                    </div>
                    <div slot="reference">{{ item.name }}</div>
                  </el-popover>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="卡片样式"
              v-if="headerStyleData.chartStyle == 6"
            >
              <el-select
                placeholder="请选择卡片样式"
                v-model="headerStyleData.cartBg.style"
                style="float: left; width: 600px"
              >
                <el-option label="渐色渐变卡片组" value="1">
                  <img
                    src="./../assets/images/jianbianzu6-2.png"
                    class="colorImgbg"
                    alt=""
                  />
                  <span style="margin-left: 10px">渐色渐变卡片组</span>
                </el-option>
              </el-select>
              <!-- <div class='titleTextColor'> -->
              <div style="float: left">
                <my-color-picker
                  v-model="headerStyleData.cartBg.color"
                  :typeSelections="2"
                />
              </div>
            </el-form-item>
            <!-- <el-form-item
              label="文字设置"
              style="font-size: 16px; font-wight: 800"
            ></el-form-item> -->
            <el-form-item label="模板文字">
              <el-select
                placeholder="请选择模板文字"
                v-model="headerStyleData.templateText.fontSize"
                style="float: left; width: 600px"
              >
                <el-option
                  v-for="num in range(14, 29, 2)"
                  :key="num"
                  :value="num + 'px'"
                  :label="num + 'px'"
                >
                  <!-- {{ num }} -->
                </el-option>
              </el-select>
              <div
                class="titleText"
                @click="
                  headerStyleData.templateText.isWeight =
                    !headerStyleData.templateText.isWeight
                "
                :style="{
                  borderColor: headerStyleData.templateText.isWeight
                    ? '#3370FF'
                    : '#ccc',
                }"
              >
                <en-icon
                  name="wenzijiacu"
                  size="12px"
                  :color="
                    headerStyleData.templateText.isWeight ? '#3370FF' : '#ccc'
                  "
                ></en-icon>
              </div>
              <div class="titleTextColor">
                <el-color-picker
                  v-model="headerStyleData.templateText.color"
                  size="small"
                ></el-color-picker>
              </div>
            </el-form-item>
            <el-form-item label="统计数">
              <el-switch v-model="headerStyleData.statisticsNum"></el-switch>
            </el-form-item>
            <el-form-item label="统计文字">
              <el-select
                placeholder="请选择统计文字"
                v-model="headerStyleData.statisticsText.fontSize"
                style="float: left; width: 600px"
              >
                <el-option
                  v-for="num in range(14, 29, 2)"
                  :key="num"
                  :value="num + 'px'"
                  :label="num + 'px'"
                >
                  <!-- {{ num }} -->
                </el-option>
              </el-select>
              <div
                class="titleText"
                @click="
                  headerStyleData.statisticsText.isWeight =
                    !headerStyleData.statisticsText.isWeight
                "
                :style="{
                  borderColor: headerStyleData.statisticsText.isWeight
                    ? '#3370FF'
                    : '#ccc',
                }"
              >
                <en-icon
                  name="wenzijiacu"
                  size="12px"
                  :color="
                    headerStyleData.statisticsText.isWeight ? '#3370FF' : '#ccc'
                  "
                ></en-icon>
              </div>
              <div class="titleTextColor">
                <el-color-picker
                  v-model="headerStyleData.templateText.color"
                  size="small"
                ></el-color-picker>
              </div>
            </el-form-item>
            <!-- <el-form-item
              label="其他设置"
              style="font-size: 16px; font-wight: 800"
            ></el-form-item> -->
            <!--
                chartStyle =  0 没有icon不显示
                chartStyle = 1，3，4 6 才有icon左右
                chartStyle = 2，5 才有icon上下
                -->
            <el-form-item
              label="对齐方式"
              v-if="headerStyleData.chartStyle != 0"
            >
              <el-select
                placeholder="请选择对齐方式"
                v-model="headerStyleData.alignment"
                style="float: left; width: 600px"
              >
                <el-option
                  label="图标居文字上方"
                  value="1"
                  v-if="
                    headerStyleData.chartStyle == 2 ||
                    headerStyleData.chartStyle == 5
                  "
                ></el-option>
                <el-option
                  label="图标居文字下方"
                  value="2"
                  v-if="
                    headerStyleData.chartStyle == 2 ||
                    headerStyleData.chartStyle == 5
                  "
                ></el-option>
                <el-option
                  label="图标居文字左方"
                  value="3"
                  v-if="
                    headerStyleData.chartStyle == 1 ||
                    headerStyleData.chartStyle == 3 ||
                    headerStyleData.chartStyle == 4 ||
                    headerStyleData.chartStyle == 6
                  "
                ></el-option>
                <el-option
                  label="图标居文字右方"
                  value="4"
                  v-if="
                    headerStyleData.chartStyle == 1 ||
                    headerStyleData.chartStyle == 3 ||
                    headerStyleData.chartStyle == 4 ||
                    headerStyleData.chartStyle == 6
                  "
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="文字排序">
              <el-select
                placeholder="请选择文字排序"
                v-model="headerStyleData.textSorting"
                style="float: left; width: 600px"
              >
                <el-option label="模板名称居上" value="1"></el-option>
                <el-option label="统计数居上" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="文字对齐">
              <el-radio-group
                v-model="headerStyleData.textAlignment"
                style="margin-bottom: 30px"
              >
                <el-radio-button label="left" value="left">左对齐</el-radio-button>
                <el-radio-button label="center" value="center">居中对齐</el-radio-button>
                <el-radio-button label="right" value="right">右对齐</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="边框设置"
              v-if="
                headerStyleData.chartStyle == 6 ||
                headerStyleData.chartStyle == 8
              "
            >
              <el-select
                placeholder="请选择"
                v-model="headerStyleData.borderStyle.style"
                style="float: left; width: 300px"
              >
                <el-option label="无" value="0"></el-option>
                <el-option label="实线" value="1">
                  <span>实线</span>
                  <span
                    style="
                      display: inline-block;
                      width: 200px;
                      margin-left: 20px;
                    "
                    ><hr
                  /></span>
                </el-option>
                <el-option label="虚线" value="2">
                  <span>虚线</span>
                  <span
                    style="
                      display: inline-block;
                      width: 200px;
                      margin-left: 20px;
                    "
                    >---------------------</span
                  >
                </el-option>
                <el-option label="粗线" value="3">
                  <span>粗线</span>
                  <span
                    style="
                      display: inline-block;
                      width: 200px;
                      margin-left: 20px;
                    "
                    ><hr style="height: 3px; background: #000"
                  /></span>
                </el-option>
              </el-select>
              <el-select
                placeholder="请选择"
                v-model="headerStyleData.borderStyle.size"
                style="float: left; width: 300px"
              >
                <el-option label="1px" value="1px"></el-option>
                <el-option label="2px" value="2px"></el-option>
                <el-option label="3px" value="3px"></el-option>
                <el-option label="4px" value="4px"></el-option>
              </el-select>
              <div class="titleTextColor">
                <el-color-picker
                  v-model="headerStyleData.borderStyle.color"
                  size="small"
                ></el-color-picker>
              </div>
            </el-form-item>
          </el-collapse-item>
          <el-collapse-item name="3">
            <template slot="title">
                <span class="iconfont title-icon-style bgicon">&#xe807;</span> <span class="collapse-title">背景样式</span>
            </template>
            <el-form-item label="背景底色">
              <div class="color-box">
                <span class="iconfont title-icon-style">&#xec8f;</span>
                  <el-color-picker
                    @change="bgStyleChange"
                    v-model="headerStyleData.bgStyle"
                    size="small"
                  ></el-color-picker>
              </div>
            </el-form-item>
          </el-collapse-item>
        </el-collapse>
      </el-form>

      <div slot="footer">
        <en-button @click="closeDialog"> 确定 </en-button>
      </div>
    </en-dialog>
  </div>
</template>

<script>
import MyColorPicker from "@/components/my-color-picker";

export default {
  name: "viewNotice",
  components: {
    MyColorPicker
  },
  props: {
    // 全部列表
    allData: {
      type: Array,
      default() {
        return [];
      }
    },
    // 公共数据
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    headerStyle: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      initData: {},
      visiable: true,
      radio: "1",
      cur: "1",
      activeNames: [],
      cartBgColor: false,
      cartBgColor1: false,
      form: {
        name: "",
        showName: false,
        titleText: {
          fontSize: "14px",
          isWeight: true,
          alignment: "iconzuoduiqi1",
          color: "#000"
        },
        iconColor: "#f00",
        titleStyle: 1,
        chartStyle: 4,
        chartColorGrou: "1",
        templateText: {
          fontSize: "14px",
          isWeight: false,
          color: "#000"
        },
        statisticsNum: false,
        statisticsText: {
          fontSize: "14px",
          isWeight: false,
          color: "#000"
        },
        alignment: "",
        textSorting: "1",
        textAlignment: "left",
        cartBg: {
          style: "1",
          color: "#fff"
        },
        borderStyle: {
          style: "0",
          size: "1px",
          color: "#fff"
        },
        bgStyle: "#fff"
      },
      titleStyleList: [
        {
          value: 1,
          name: "样式一",
          desc: "样式一：框外纯文字",
          imgSrc: require("../assets/images/headStyle1-1.png")
        },
        {
          value: 2,
          name: "样式二",
          desc: "样式二：框外图标加文字",
          imgSrc: require("../assets/images/headStyle1-2.png")
        },
        {
          value: 3,
          name: "样式三",
          desc: "样式三：框内纯文字",
          imgSrc: require("../assets/images/headStyle1-3.png")
        },
        {
          value: 4,
          name: "样式四",
          desc: "样式四：框内图标加文字",
          imgSrc: require("../assets/images/headStyle1-4.png")
        }
      ],
      iconStyleList: [
        {
          value: 1,
          name: "样式一",
          desc: "样式一：统计数、板名称",
          imgSrc: require("../assets/images/iconStyle2-1.png")
        },
        {
          value: 2,
          name: "样式二",
          desc: "样式二：图标、模板名称、统计数",
          imgSrc: require("../assets/images/iconStyle2-2.png")
        },
        {
          value: 3,
          name: "样式三",
          desc: "样式三：图标、模板名称、统计数",
          imgSrc: require("../assets/images/iconStyle2-3.png")
        },
        {
          value: 4,
          name: "样式四",
          desc: "样式四：图标、模板名称、统计数",
          imgSrc: require("../assets/images/iconStyle2-4.png")
        },
        {
          value: 5,
          name: "样式五",
          desc: "样式五：图标、统计数、模板名称",
          imgSrc: require("../assets/images/iconStyle2-5.png")
        },
        {
          value: 6,
          name: "样式六",
          desc: "样式六：图标、统计数、模板名称",
          imgSrc: require("../assets/images/iconStyle2-6.png")
        },
        {
          value: 7,
          name: "样式七",
          desc: "样式七：图标、模板名称、统计数",
          imgSrc: require("../assets/images/iconStyle2-7.png")
        },
        {
          value: 8,
          name: "样式八",
          desc: "样式八：图标、模板名称、统计数",
          imgSrc: require("../assets/images/iconStyle2-8.png")
        }
      ]
      // form:
    };
  },
  computed: {
    // 计算是否是子级菜单

    isChild() {
      console.log(this.allData, "this.allDatathis.allDatathis.allDatathis.allDatathis.allData", this.initData);
        return !(this.initData?.dataList?.length > 0);
    }
    // headerStyleData() {
    //   return this.form;
    // }
  },
  watch: {
    headerStyle(newValue) {
      console.log(newValue, "headerStyleWatch");
    }
  },
  created() {
    // 记录初始数据
    this.initData = JSON.parse(JSON.stringify(this.data));
    if (this.isEmpty(this.headerStyle)) {
      console.log("没有数据传入！采用默认配置");
      this.headerStyleData = this.form;
    } else {
      this.headerStyleData = this.headerStyle;
    }
    //   if(this.headerStyleData){
    // this.headerStyleData =
    //   this.headerStyleData = this.form;
  },
  mounted() {
    // console.log(this.headerStyleData);
    // this.headerStyleData = this.form;
    // if (!this.headerStyleData) {
    //     this.headerStyleData = this.form;
    // }
  },
  methods: {
    chartStyleChange(val) {
      // if (this.headerStyleData.chartStyle === 0) {
      //     this.headerStyleData.alignment = "0";
      // }
      this.headerStyleData.alignment = "";
    },
    bgStyleChange(value) {
      this.$emit("close", this.headerStyleData);
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    range(start, end, step) {
      const result = [];
      for (let i = start; i <= end; i += step) {
        result.push(i);
      }
      return result;
    },
    cartBgColor1Close() {
      this.cartBgColor = false;
    },
    changeColor(data) {
    },
    handleClick(event) {
      event.stopPropagation(); // 阻止事件继续传播
    },
    cartBgChange(data) {
      if (data === 2) {
        console.log("3");
      }
    },
    titleTextAligen(data) {
      this.headerStyleData.titleText.alignment = data;
    },
    handleChange(val) {
    },
    // 关闭回调
    closeDialog() {
      if (this.data.name !== this.initData.name) {
        // 如果 自定义的导航标题被更改了
        this.$emit("changeCoutData", this.data);
      }
      console.log(this.headerStyleData, "1231312312312312313123131231231312");
      this.$emit("close", this.headerStyleData);
    }
  }
};
</script>

<style lang="scss" scoped>
.gradientColor{
    height:32px;
    width:32px;
    border:1px solid #ccc;
    border-radius:4px;
    margin-left:15px;
    text-align: center;
}
/deep/ .el-collapse-item__wrap {
  // overflow: initial !important ;
}
/deep/ .el-dialog {
  // overflow: initial !important ;
}
/deep/ .el-dialog__body{
  max-height: 600px;
    overflow: auto;
}
/deep/ .color-dialog {
  position: absolute;
  z-index: 999;
  border: 1px solid #ccc;
  border-radius: 5px;
}
/deep/ .color_picker_box {
  // border: 1px solid rgb(255, 0, 0);
  // background: rgb(255, 255, 0);
  // display: inline-block;
  // top:28px;
  // left:28px;
}
/deep/ .el-form-item__label{
  text-align: left;
}
/deep/ .el-color-picker__icon{
  display: none;
}
/deep/ .el-color-picker__empty{
  display: none;
}
.colorImgbg {
  display: block;
  float: left;
  margin-top: 9px;
}
.titleText {
  float: left;
  margin-left: 15px;
  height: 32px;
  width: 32px;
  border: 1px solid #ccc;
  text-align: center;
}
.titleTextColor {
  float: left;
  margin-left: 15px;
  height: 32px;
  width: 32px;
}
.titleStyle {
  // width:305px;
  // height:157px;
  .styleDemoImg {
    display: block;
    width: 280px;
    height: 110px;
  }
  .iconstyleDemoImg {
    display: block;
    width: 272px;
    height: 136px;
  }
}
/deep/ .collapse-title{
  font-size: 14px;
}
.title-icon-style{

  padding-right: 5px;
}
.titicon{
  color: #FFA32B;
  font-size: 16px;
}
.echarticon{
  color: #3E90FE;
  font-size: 16px;
}
.bgicon{
  color: #47BAFF;
  font-size: 16px;
}
.collapse-title{
  font-family: Microsoft YaHei;
  font-weight: 600;
  color: #1A1C1E;
}
.color-box{
  display: flex;
  align-items: center;
}
.color-box{
  /deep/ .el-color-picker{
    display: flex;
    align-items: center;

  }
  /deep/ .el-color-picker--small .el-color-picker__trigger{
    width: 18px;
    height: 18px;
    padding: 0;
  }
}
.el-dropdown{
  /deep/ .en-icon:focus{
    outline: none;
    color: #3370FF;
  }
  // /deep/ .en-icon:active{

  // }
}
/deep/ .el-collapse-item__content{
  padding-bottom: 0;
  border-bottom : 1px solid #E8ECF2;
}
/deep/ .el-collapse{
  border-top:none;
}
/deep/ .el-collapse-item__arrow{
  color: #A9B5C6;
}
</style>
