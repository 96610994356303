var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "my-notice" }, [
    _c(
      "div",
      { staticClass: "my-notice-head" },
      [
        _c("en-icon", {
          attrs: { name: "fanhui", size: "12px" },
          nativeOn: {
            click: function ($event) {
              return _vm.$router.push("/myEn")
            },
          },
        }),
        _c("div", { staticClass: "label" }, [_vm._v(" 我的消息 ")]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "my-notice-body" },
      _vm._l(_vm.myNotice.records, function (item) {
        return _c("div", { key: item.id, staticClass: "my-notice-data" }, [
          _c("div", { staticClass: "my-notice-data-basic" }, [
            _c(
              "div",
              {
                staticClass: "my-notice-data-icon",
                style: { "background-color": _vm.iconReplaceBg(item.code) },
              },
              [_c("en-icon", { attrs: { name: "shujuxiangqing" } })],
              1
            ),
            _c("div", { staticClass: "my-notice-data-name" }, [
              _vm._v(" " + _vm._s(item.message) + " "),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "my-notice-data-status" },
            [
              _c("div", { staticClass: "my-notice-data-date" }, [
                _vm._v(" " + _vm._s(item.endTime) + " "),
              ]),
              _c("el-tag", [_vm._v(" " + _vm._s(item.billStatusName) + " ")]),
            ],
            1
          ),
        ])
      }),
      0
    ),
    _vm.myNotice.totalPage > 1
      ? _c("div", { staticClass: "my-notice-more" }, [
          _vm._v(
            " 加载更多(" +
              _vm._s(_vm.myNotice.total - _vm.myNoticePage * 5) +
              ") "
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }