var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "normal-home" }, [
    _c(
      "div",
      { staticClass: "main-container" },
      [
        _c(
          "grid-layout",
          {
            attrs: {
              layout: _vm.dataList,
              "col-num": 12,
              "row-height": 80,
              "is-draggable": false,
              "is-resizable": false,
              "is-mirrored": false,
              "vertical-compact": true,
              "use-css-transforms": true,
              margin: [8, 8],
            },
            on: {
              "update:layout": function ($event) {
                _vm.dataList = $event
              },
            },
          },
          _vm._l(_vm.dataList, function (item, index) {
            return _c(
              "grid-item",
              {
                key: index,
                attrs: {
                  x: item.x,
                  y: item.y,
                  w: item.w,
                  h: item.h,
                  i: index,
                  static: true,
                },
              },
              [
                _c("card-define", {
                  key: item.data.id + index,
                  attrs: {
                    "menu-data": item.data,
                    "classify-list": _vm.classifyList,
                  },
                }),
              ],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }