<template>
  <div class="my-task">
    <div class="my-task-head">
      <en-icon name="fanhui" size="12px" @click.native="$router.push('/myEn')">
      </en-icon>
      <div class="label">
        我的任务
      </div>
    </div>
    <div class="my-task-body">
      <div v-for="item in myTask.records" :key="item.id" class="my-task-data">
        <div class="my-task-data-basic">
          <div class="my-task-data-icon">
            <en-icon name="shujuxiangqing"> </en-icon>
          </div>
          <div class="my-task-data-name">
            {{ item.name }}
          </div>
        </div>
        <div class="my-task-data-status">
          <div class="my-task-data-date">
            {{ item.endTime }}
          </div>
          <el-tag>
            {{ item.billStatusName }}
          </el-tag>
        </div>
      </div>
    </div>
    <div v-if="myTask.totalPage > 1" class="my-task-more">
      加载更多({{ myTask.total - myTaskPage * 5 }})
    </div>
  </div>
</template>

<script>
import myTask from "./data/myTask";

export default {
  name: "MyTask",
  data() {
    return {
      myTaskPage: 1,
      myTask
    };
  }
};
</script>

<style lang="scss" scoped>
.my-task {
  width: 100%;
  margin-bottom: 10px;
  overflow-y: auto;

  .my-task-head {
    display: flex;
    justify-content: flex-start;
    background-color: #ffffff;
    border-bottom: 1px solid #e8ecf2;
    padding: 0 20px;
    position: relative;
    line-height: 50px;

    .en-icon {
      color: #a9b5c6;
      margin-top: 18px;
      margin-right: 5px;
      cursor: pointer;
    }

    .label {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }

  .my-task-body {
    background-color: #ffffff;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
    width: 100%;
    padding: 0 10px;

    .my-task-data {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 15px 10px;
      border-bottom: 1px solid #e8ecf2;

      .my-task-data-basic {
        display: flex;
        justify-content: flex-start;

        .my-task-data-icon {
          width: 40px;
          height: 40px;
          background-color: #26c393;
          color: #ffffff;
          -webkit-border-radius: 100%;
          -moz-border-radius: 100%;
          border-radius: 100%;
          padding: 11px 11px 11px;

          .en-icon {
            width: 18px;
            height: 18px;
          }
        }

        .my-task-data-name {
          line-height: 40px;
          margin-left: 15px;
          font-size: 13px;
          font-weight: 400;
          color: #333333;
        }
      }

      .my-task-data-status {
        text-align: right;

        .my-task-data-date {
          color: #91a1b7;
          font-size: 12px;
          margin-bottom: 7px;
        }
      }
    }
  }

  .my-task-more {
    color: #91a1b7;
    font-size: 12px;
    cursor: pointer;
    padding: 13px 0 14px;
    background-color: #ffffff;
  }
}
</style>
