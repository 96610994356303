<template>
  <!-- <menu-define-add
    v-if="isEdit"
    :configMenuDatas="configMenuDatas"
    :menu-data="editData"
    :key="index"
    @complete="editComplete"
    @deleteData="isEdit = false"
  >
  </menu-define-add> -->
  <div  class="menu-define-group">
    <el-checkbox :class="isDraggable ? 'isDraggable':''" v-model="selected" @change="changeSelect"></el-checkbox>
    <div class="bgContainer" :class="isDraggable ? 'isDraggable':''">
      <div class="container" draggable="true" :class="classStyle">
        <div class="content" @click="changeOpen">
          <div class="content-left">
            <div class="content-left-icon" :style="'background-color:' + color ">
              <en-icon :name="icon"></en-icon>
            </div>
            <div class="content-left-infos">
              <div class="content-left-infos-title">{{ menuData.name }}</div>
              <div class="content-left-infos-detail">{{ menuData.description || detailText }}</div>
            </div>
          </div>
          <div style="display:flex;align-items:center">
          <el-dropdown class="content-edit" :style= "{'margin-right':hasChildren ? '40px':'17px'}" :hide-on-click="false" v-if="showEdit">
            <en-icon name="bianji1"></en-icon>
            <el-dropdown-menu   slot="dropdown">
              <el-dropdown-item class='drop-menus-custom' @click.native="startEdit">编辑</el-dropdown-item>
              <el-dropdown-item  v-if="menuData.type==1||menuData.type==8" class='drop-menus-custom' @click.native="deleteData">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <en-icon v-if="hasChildren" class="content-arrow" :name="open ? 'zhankai':'xiayiye'"></en-icon>
          </div>
        </div>
        <template v-if="hasChildren && open">
          <div class="children kanban" v-if="isKanban">
            <template v-for="(item1, index1) in kanbanDataList">
              <div class="children-title" :key="index1">{{ item1[0].refData.mdName }}</div>
              <menu-define-item
                v-for="(item2, index2) in item1"
                :key="index2"
                :draggable="false"
                :menu-data="item2"
                @change="itemChange"
              >
              </menu-define-item>
            </template>
          </div>
          <!-- <draggable
            v-else
            class="children"
            v-model="dataList"
            draggable=".menu-define-item"
            @start="start"
            @end="end">
            <menu-define-item
              v-for="(item, index) in dataList"
              :key="index"
              :draggableFlg="true"
              :menu-data="item"
              :is-draggable="draggableData === item"
              @change="itemChange"
            >
            </menu-define-item>
          </draggable> -->
          <menu-subsetlist v-else :menu="menuData" :configMenuDatas='configMenuDatas' isKanBan  @change='subSetListChange'></menu-subsetlist>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import draggable from "vuedraggable";
import menuDefineItem from "./menu-define-item";
import menuSubsetlist from "./menu-subsetlist";

export default {
  name: "menu-define-group",
  components: {
    menuDefineItem,
    menuSubsetlist
  },
  props: {
    menuData: {
      type: Object
    },
    isDraggable: {
      type: Boolean,
      default: false
    },
    configMenuDatas: {
      type: Array
    }
  },
  data() {
    return {
      open: false,
      selected: false,
      draggableData: null,
      dataList: null,
      isEdit: false,
      editData: {}
    };
  },
  watch: {
    selected(val) {
      this.menuData.isShow = val ? 0 : 1;
    }
  },
  created() {
    this.selected = this.isShow;
    this.dataList = this.menuData?.dataList;
  },
  computed: {
    isShow() {
      return this.menuData.isShow === 0;
    },
    hasChildren() {
      return this.dataList?.length > 0;
    },
    icon() {
      return this.menuData.showIcon || this.menuData.customIcon;
    },
    showEdit() {
      return !["2_7", "2_13", "2_17", "2_4", "2_5", "2_9", "2_8", "2_18", "2_12"].includes(this.menuData.icon);
    },
    color() {
      return this.menuData.color || this.menuData.customColor;
    },
    isKanban() {
      return this.menuData?.icon === "2_12";
    },
    kanbanDataList() {
      const array = [];
      const whileArray = [...this.dataList];
      while (whileArray.length > 0) {
        const groupData = whileArray[0];
        const groupArray = whileArray.filter((item) => item.refData.refId === groupData.refData.refId);
        groupArray.forEach((item) => {
          const index = whileArray.indexOf(item);
          whileArray.splice(index, 1);
        });
        array.push(groupArray);
      }
      return array;
    },
    classStyle() {
      return [this.open ? "open" : "normal"];
    },
    detailText() {
      const infos = {
        "2_12": "自定义看板",
        "2_1": "随时随地的便捷",
        "2_2": "看得见的OA，看不见的数据流动",
        "2_3": "运筹帷幄，明察秋毫",
        "2_6": "对象管理、接口操作、下载中心",
        "2_10": "业财数据自动翻译"
      };
      if (infos[this.menuData.icon]) {
        return infos[this.menuData.icon];
      }
      if (this.menuData.type === 3) {
        return "业务建模";
      }
      return "";
    }
  },
  methods: {
    changeOpen() {
      if (this.hasChildren) {
        this.open = !this.open;
      }
    },
    subSetListChange(data) {
      console.log(data);
      this.$emit("change", data);
    },
    start(evt) {
      const self = this;
      setTimeout(() => {
        self.draggableData = self.dataList[evt.oldIndex];
      }, 0.1);
    },
    end() {
      this.draggableData = null;
      this.menuData.dataList = this.dataList;
    },
    changeSelect(val) {
      this.dataList.forEach((item) => {
        item.isShow = val ? 0 : 1;
      });
    },
    itemChange() {
      const data = this.dataList.filter((item) => item.isShow === 0);
      this.selected = data.length > 0;
    },
    startEdit() {
      this.editData = JSON.parse(JSON.stringify(this.menuData));
      const datas = [];
      this.menuData?.dataList.forEach((item) => {
        const itemData1 = { label: item.name, value: item.refId, data: item };
        datas.push(itemData1);
      });
      this.editData.color = this.editData.color || this.editData.customColor;
      this.editData.showIcon = this.editData.showIcon || this.editData.customIcon;
      this.editData.selectDatas = datas;
      this.editData.selected = this.menuData.isShow === 0;
      this.editData.type = this.menuData.type;
      this.isEdit = true;
      this.$emit("startEdit", this.editData);
      // this.conte;
    },
    deleteData() {
      this.$emit("deleteData", this.menuData);
    },
    editComplete(data) {
      this.isEdit = false;
      this.menuData.name = data.name;
      this.menuData.isShow = this.editData.selected ? "0" : "1";
      this.menuData.dataList = data.dataList;
      this.dataList = this.menuData.dataList;
      this.$emit("edit", this.menuData);
    }
  }
};
</script>

<style lang="scss" scoped>

.menu-define-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  .el-checkbox {
    line-height: 80px;
  }
  .el-checkbox,
  .bgContainer {
    margin-left: 16px;
  }
  .isDraggable {
    &.el-checkbox {
      visibility: hidden;
    }
    .container {
      visibility: hidden;
    }
  }
  .bgContainer {
    flex: 1;
    background-color: #E1E7F1;
    margin-right: 20px;
    border-radius:4px;
  }
  .container {
    border-radius:4px;
    border:1px solid rgba(231,235,241,1);
    background-color: white;
  }
  .open {
    &.container {
      border:1px solid rgba(161, 205, 247, 1);
      box-shadow:0px 4px 6px 0 rgba(47,120,154,0.15);
      .content {
        border-bottom:1px solid rgba(231,235,241,1);
      }
    }
  }
  .children {
    // display: inline-block;
    display:block;
    padding-right: 10px;
    padding-bottom: 17px;
    background-color: #F5F7FAFF;
    .children-title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding-left: 20px;
      margin-bottom: -16px;
      font-size: 14px;
    }
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    position: relative;
    .content-left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .content-left-icon {
        width: 40px;
        height: 40px;
        margin-left: 17px;
        text-align: center;
        border-radius:50%;
        display: inline-flex;//flex布局
        justify-content: center;//x轴排列
        align-items: center;//y轴排列
        .en-icon {
          width: 18px;
          height: 18px;
          color: white;
          border-radius:3px;
        }
      }
      .content-left-infos {
        margin-left: 12px;
        font-size:12px;
        .content-left-infos-title {
          color: #333333;
        }
        .content-left-infos-detail {
          color: #91A1B7;
          margin-top: 10px;
        }
      }
    }
    .content-edit {
      margin-right: 40px; 
      display: none;
      .en-icon {
        color: #3e90fe;
        width:16px;
        height:16px;
      }
    }
    .drop-menus{
      background: red;
      .el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover{
        color: red;
      }
    }
    .content-arrow {
      position: absolute;
      right: 17px;
      width:12px;
      // height:14px;
      color: #3e90fe;
    }
  }
  :hover {
    .content-edit {
      display: block;
    }
  }
}

</style>
