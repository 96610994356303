<!--
 * @Author: wuqi
 * @Date: 2021-12-25 13:53:46
 * @LastEditors: linshanfeng
 * @LastEditTime: 2024-02-26 15:33:34
 * @Description:
-->
<template>
  <en-dialog

    width="50%"
    style="text-align: left"
    v-bind="$attrs"
    v-on="$listeners"
    title="添加应用项"
    @open="openCallBack"
    :modal-append-to-body='false'
  >
    <div class="content">
      <el-form ref="form" :model="classifyInfo" label-width="80px">
          <el-form-item label="选择应用" prop="selectDatas" :rules="[{ type: 'array', required: true, message: '请至少选择一个应用', trigger: 'change' }]">
             <en-select
              v-model="classifyInfo.selectDatas"
              checkMode = 'siblings'
              :data="optionDatas"
              mode="tree"
              :multiple="true"
              data-mode="data"
              class='template-select'
              placeholder="请选择"></en-select>
          </el-form-item>

      </el-form>
    </div>
    <div slot="footer" style="height: 32px">
      <en-button @click.native="complete">
        确定
      </en-button>
    </div>
  </en-dialog>
</template>

<script>
// import { cloneDeep } from "lodash";
import {
 menuClassifyTypeEnum
} from "@/api/homeMenu/handleMenu";

export default {
  name: "edit-app-dialog",
  data() {
    return {
      classifyInfo: {
        selectDatas: []
      }
    };
  },
  props: {
    configMenuDatas: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {

    optionDatas() {
      const result = [];
      // const datas = this.configMenuDatas.filter((item) => item.type !== 1 || item.type !== 8);
      const datas = this.configMenuDatas.filter((item) => item.type !== 1 && item.type !== 8 && item.type !== 0 && item.icon !== "2_7"
              && item.icon !== "2_8"
              && item.icon !== "2_9"
              && item.icon !== "2_13" && item.icon !== "2_12" && item.dataType !== "10");
      datas.forEach((item) => {
        const itemData = { label: item.name, value: item.id, data: item };
        if (item?.dataList) {
          itemData.children = [];
          item.dataList.forEach((item1) => {
            const itemData1 = { label: item1.name, value: item1.refId, data: item1 };
            itemData.children.push(itemData1);
          });
        }
        result.push(itemData);
      });
      return result.filter(it=> it.children?.length > 0);
    }
  },
  created() {

  },
  mounted() {
  },
  methods: {
    complete() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit("complete", this.classifyInfo.selectDatas);
          this.classifyInfo.selectDatas = [];
          return true;
        }
          console.log("error submit!!");
          return false;
      });
    },

    setSort(options) {
     const menuData = options.find((item) => item.data.customType !== menuClassifyTypeEnum.home
                                            && item.data.customType !== menuClassifyTypeEnum.myMatter
                                            && item.data.customType !== menuClassifyTypeEnum.helpCenter);
     console.log(menuData.data);
     return Number(menuData.data.sorts);
    },
    openCallBack() {

    }
  }
};
</script>

<style lang='scss' scoped>
.template-select{
  width: 100%;
}
/deep/ .el-form .el-form-item__label{
  text-align: left;
}
</style>
