<template>
  <div class="date-picker">
    <div class="date-picker-title">
      <en-icon
        name="fanhui"
        size="12px"
        @click.native="$router.push('/myEn')"
      >
      </en-icon>
      <div class="label">
        日历
      </div>
    </div>
    <div class="date-picker-header">
      <en-icon
        class="date-picker-prev"
        name="fanhui"
        size="10px"
        @click.native="changeMonth(-1)"
      >
      </en-icon>
      <div class="label">
        {{ dateInfo.year + "/" + dateInfo.month }}
      </div>
      <en-icon
        class="date-picker-next"
        name="fanhui"
        size="10px"
        @click.native="changeMonth(1)"
      >
      </en-icon>
    </div>
    <div class="date-picker-week">
      <div
        v-for="item in weekArr"
        :key="item"
        class="date-picker-week-item"
      >
        {{ item }}
      </div>
    </div>
    <div class="date-picker-main">
      <div
        v-for="item in dateArr"
        :key="item.timeStamp"
        :class="{
          'active':currentDateInfo === item.timeStamp,
          'other-month':item.month !== dateInfo.month
        }"
        class="date-picker-item"
      >
        <div class="date-info">
          {{ item.date }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWholeMonthData, getTimeStamp } from "en-js";

export default {
  name: "DatePicker",
  data() {
    return {
      weekArr: [
        "日",
        "一",
        "二",
        "三",
        "四",
        "五",
        "六"
      ],
      dateArr: [],
      currentDateInfo: "",
      dateInfo: {
        year: "",
        month: "",
        date: "",
        beginDate: "",
        dateLength: ""
      }
    };
  },
  mounted() {
    this.currentDateInfo = getTimeStamp(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      new Date().getDate()
    );
    this.getDateInfo();
  },
  methods: {
    /**
     * @description 初始化获取当月日历数据信息
     */
    getDateInfo() {
      const d = new Date();
      this.dateInfo = {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        date: d.getDate(),
        timeStamp: getTimeStamp(d.getFullYear(), d.getMonth() + 1, d.getDate())
      };
      this.dateArr = getWholeMonthData(this.dateInfo.year, this.dateInfo.month, 1);
    },
    /**
     * @description 切换月份
     * @param type
     */
    changeMonth(type) {
      const dateInfo = { ...this.dateInfo };
      if (type === 1) {
        this.dateInfo = {
          year: dateInfo.month === 12 ? dateInfo.year + 1 : dateInfo.year,
          month: dateInfo.month === 12 ? 1 : parseInt(dateInfo.month, 10) + 1,
          date: null,
          timeStamp: null,
          dateLength: null
        };
      } else {
        this.dateInfo = {
          year: dateInfo.month === 1 ? dateInfo.year - 1 : dateInfo.year,
          month: dateInfo.month === 1 ? 12 : parseInt(dateInfo.month, 10) - 1,
          date: null,
          timeStamp: null,
          dateLength: null
        };
      }
      this.getDateInfo();
    }
  }
};
</script>

<style lang="scss" scoped>
  .date-picker {
    .date-picker-title {
      display: flex;
      justify-content: flex-start;
      background-color: #ffffff;
      border-bottom: 1px solid #E8ECF2;
      padding: 0 20px;
      position: relative;
      align-items: center;
      line-height: 50px;

      .en-icon {
        color: #A9B5C6;
        margin-right: 5px;
        cursor: pointer;
      }

      .label {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }
    }

    .date-picker-header {
      background-color: #F7F9F9;
      display: flex;
      justify-content: flex-start;
      height: 44px;
      line-height: 44px;
      padding: 0 20px;
      font-size: 12px;
      border-bottom: 1px solid #E8ECF2;

      .en-icon {
        margin-top: 16px;

        &.date-picker-next {
          transform: rotate(180deg);
        }
      }
    }

    .date-picker-week {
      display: flex;
      justify-content: space-between;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid #E8ECF2;
      position: relative;
      z-index: 2;

      .date-picker-week-item {
        width: calc(100% / 7);
        line-height: 48px;
        background-color: #F6FAFD;
        border-right: 1px solid #E8ECF2;
        font-size: 12px;
        color: #333333;

        &:nth-last-child {
          border-right: none;
        }
      }
    }

    .date-picker-main {
      background-color: #ffffff;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .date-picker-item {
        width: calc(100% / 7);
        border-right: 1px solid #E8ECF2;
        border-bottom: 1px solid #E8ECF2;
        height: 131px;
        position: relative;
        cursor: pointer;

        &:nth-child(7n) {
          border-right: none;
        }

        .date-info {
          width: 24px;
          height: 24px;
          -webkit-border-radius: 4px 4px 4px;
          -moz-border-radius: 4px 4px 4px;
          border-radius: 4px 4px 4px;
          line-height: 24px;
          position: absolute;
          right: 14px;
          top: 7px;
          font-size: 12px;
        }

        /* 当前天颜色区域 */
        &.active {
          .date-info {
            background-color: #3e90fe;
            color: #ffffff;
          }
        }

        /* 面板数据悬浮颜色区分 */
        &:hover {
          background-color: #F5F9FD;
        }

        /* 其他月份颜色区域 */
        &.other-month {
          .date-info {
            color: #CDCDCD
          }
        }
      }
    }
  }
</style>
