var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: { placement: "bottom-start", trigger: "click" },
      on: { show: _vm.showCallback },
    },
    [
      _c("div", { staticClass: "icon-wrap" }, [
        _c(
          "div",
          { staticClass: "icon-wrap-img" },
          _vm._l(_vm.icons, function (iconItem) {
            return _c(
              "span",
              {
                key: iconItem,
                staticClass: "item",
                class: { active: _vm.modelValue.showIcon === iconItem },
                on: {
                  click: function ($event) {
                    return _vm.setCustomIcon(iconItem)
                  },
                },
              },
              [
                _c("en-icon", {
                  style: {
                    color:
                      _vm.modelValue.showIcon === iconItem ? "#fff" : "#179eda",
                  },
                  attrs: {
                    name: _vm.iconPrefix + iconItem + _vm.iconSuffix,
                    size: "14px",
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "icon-wrap-color" },
          _vm._l(_vm.colors, function (colorItem, ind) {
            return _c("span", { key: ind, staticClass: "item" }, [
              _c(
                "span",
                {
                  staticClass: "ring",
                  style: { background: colorItem },
                  on: {
                    click: function ($event) {
                      return _vm.setCustomColor(colorItem)
                    },
                  },
                },
                [
                  _vm.modelValue.color === colorItem
                    ? _c("en-icon", {
                        attrs: {
                          name: "tongyianniu-kuaijiecaozuo",
                          size: "14px",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ])
          }),
          0
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "show-icon",
          style: { "background-color": _vm.modelValue.color },
          attrs: { slot: "reference" },
          slot: "reference",
        },
        [
          _c("en-icon", {
            staticStyle: { color: "#fff" },
            attrs: {
              name: _vm.iconPrefix + _vm.modelValue.showIcon + _vm.iconSuffix,
              size: "18px",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }