<!--
 * @Author: wuqi
 * @Date: 2021-12-25 13:53:46
 * @LastEditors: wuqi
 * @LastEditTime: 2021-12-31 10:29:55
 * @Description:
-->
<template>
  <en-dialog

    width="50%"
    style="text-align: left"
    v-bind="$attrs"
    v-on="$listeners"
    title="添加外部链接"
    @open="openCallBack"
    :modal-append-to-body='false'
  >
    <div class="content">
      <en-table
        :head-end-config="{type:`${initData.type!=='edit'?'add':null}`}"
        :data="tableData" :edit-config="{}" :hover-row-config="{ dataMethod: buttonData, changeMethod: buttonClick }">
        <en-table-column type="index" width='50'></en-table-column>
        <!-- <en-table-column title="图标" width='80' field="icon" v-slot:edit="{ row }">
          <icon-color-picker :colors='customColorList' :icons='customIconList' v-model="row.icon" :icon-prefix='"erjifenlei"' :icon-suffix='"_2"' ></icon-color-picker>
        </en-table-column> -->
        <en-table-column title="名称" field="name" v-slot:edit="{ row, rowIndex }">
          <el-form :model="row" :ref="`rowForm${rowIndex}1`" style="height: 45px">
            <el-form-item
              prop="name"
              :rules="[{validator: validateType, trigger: 'blur' }]"
            >
              <el-input type="name" v-model="row.name" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
        </en-table-column>
        <en-table-column title="链接地址" field="outUrl" v-slot:edit="{ row, rowIndex }">
          <el-form :model="row" :ref="`rowForm${rowIndex}2`"  style="height: 45px">
            <el-form-item
              prop="outUrl"
              :rules="[{validator: validateUrl, trigger: 'blur' }]"
            >
              <el-input type="outUrl" v-model="row.outUrl" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
        </en-table-column>
        <en-table-column title="显示方式" field="outType" v-slot:edit="{ row }">
          <en-select
                class='link-type-select'
                v-model="row.outType"
                :data="optionShow"
                :props='defaultProps'
                placeholder="请选择"></en-select>

        </en-table-column>
      </en-table>
    </div>
    <div slot="footer" style="height: 32px">
      <en-button @click.native="complete">
        确定
      </en-button>
    </div>
  </en-dialog>
</template>

<script>
import { cloneDeep } from "lodash";
import { nanoid } from "nanoid";
import {
 menuClassifyTypeEnum
} from "@/api/homeMenu/handleMenu";
// import iconColorPicker from "./icon-color-picker";

export default {
  name: "edit-link-dialog",
  data() {
    return {
      tableData: [],
      optionShow: [{ id: 2, text: "在当前窗口显示" }, { id: 1, text: "在新窗口显示" }],
      defaultProps: { value: "id", label: "text" },
      customColorList: ["#3e90fe", "#26C393", "#F7BF27", "#FFAD2C", "#F76B6B", "#D69E79", "#9367EB"],
      customIconList: ["2", "3", "4", "5", "6"]
    };
  },
  props: {
    initData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    // iconColorPicker
  },
  computed: {

  },
  created() {

  },
  mounted() {
  },
  watch: {
    "tableData.length": {
      handler(newVal, oldVal) {
        if (newVal > oldVal) {
          this.tableData[0].outType = 2;
        }
      }
    }
  },
  methods: {
    complete() {
      const needlen = this.tableData.length * 2;
      let hasOk = 0;
      ["1", "2"].forEach((index) => {
        this.tableData.forEach((item, ind) => {
        // eslint-disable-next-line consistent-return
        this.$refs[`rowForm${ ind}${index}`].validate((valid) => {
          if (valid) {
            hasOk++;
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      });
      });

      if (hasOk < needlen) {
        return false;
      }

      const result = this.tableData.map((item) => ({
          name: item.name,
          outUrl: item.outUrl,
          outType: item.outType,
          showIcon: "waibulianjie",
          color: "#3e90fe",
          customIcon: "waibulianjie",
          customColor: "#3e90fe",
          channel: "PC",
          code: "075",
          commonSorts: "1",
          dataType: 15,
          isShow: 0,
          refId: item.refId || nanoid()
        }));

      this.$emit("complete", result, this.initData);
      this.tableData = [];
      return true;
    },
    /**
     * @description
     * @param 表格操作列按钮
     */
    buttonData() {
      const btnList = [
        {
          icon: "shanchu-liebiao",
          id: "btn-del",
          name: "删除",
          iconColor: "#56ca7f"
        }
      ];

      return this.initData?.type === "edit" ? [] : btnList;
    },
    /**
     * @description
     * @param 表格操作列按钮事件
     */
    buttonClick({ row, index, btnData }) {
      switch (btnData.id) {
        case "btn-del":
          // 删除
          this.deleteRow(row, index);
          break;
        default:
          break;
      }
    },
    deleteRow(row, index) {
      this.tableData.splice(index, 1);
    },
    validateUrl(rule, value, callback) {
      const s = value || "";
      // eslint-disable-next-line no-useless-escape
      const reg = /^(https?)\:\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]$/;
      const isOk = reg.test(s);
      console.log(isOk);
      if (!isOk) {
        callback(new Error("请输入正确的链接地址"));
      } else {
        callback();
      }
    },
    validateType(rule, value, callback) {
      const s = value || "";
      if (!s) {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    },
     handleMoreChange(params) {
      const str = JSON.stringify(params);
      this.$message(`调用接口直接用:${ str.substr(0, Math.min(str.length, 100)) }...`);
    },
    setSort(options) {
     const menuData = options.find((item) => item.data.customType !== menuClassifyTypeEnum.home
                                            && item.data.customType !== menuClassifyTypeEnum.myMatter
                                            && item.data.customType !== menuClassifyTypeEnum.helpCenter);
     console.log(menuData.data);
     return Number(menuData.data.sorts);
    },
    openCallBack() {
      if (this.initData.type === "edit") {
        this.tableData = cloneDeep(this.initData.data);
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.template-select{
  width: 100%;
}
</style>
