<!--
 * @Author: wuqi
 * @Date: 2021-12-25 13:53:46
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-01-31 09:07:05
 * @Description:
-->
<template>
  <en-dialog
    class="edit-cash-object"
    width="50%"
    style="text-align: left"
    v-bind="$attrs"
    v-on="$listeners"
    :title="dialogTitle"
    @open="openCallBack"
    :append-to-body='true'
    :modal-append-to-body='false'
    app
  >
    <div class="content">
      <el-form ref="form" :model="classifyInfo" label-width="80px">
          <el-form-item label="选择图标" >
            <icon-color-picker :colors='customColorList' :icons='customIconList' v-model="classifyInfo" :icon-prefix='"erjifenlei"' :icon-suffix='"_2"' ></icon-color-picker>
          </el-form-item>
          <el-form-item label="分类名称" prop="name"  :rules="[
            { required: true, message: '请输入分类名称', trigger: 'blur' },
          ]">
             <el-input class='width-50' v-model="classifyInfo.name"></el-input>
          </el-form-item>
      </el-form>
    </div>
    <div slot="footer" style="height: 32px">
      <en-button @click.native="complete">
        确定
      </en-button>
    </div>
  </en-dialog>
</template>

<script>
import { cloneDeep } from "lodash";
import { generateNanoid } from "@/tools/util";
import iconColorPicker from "./icon-color-picker";
 // import the component
// import the styles

export default {
  name: "edit-classify-dialog",
  data() {
    return {
      classifyInfo: {
        name: "",
        showIcon: "2",
        color: "#3e90fe"
      },
      selectDatas: [],
      customColorList: ["#3e90fe", "#26C393", "#F7BF27", "#FFAD2C", "#F76B6B", "#D69E79", "#9367EB"],
      customIconList: ["2", "3", "4", "5", "6"]
    };
  },
  props: {
    info: Object,
    configMenuDatas: {
      type: Array,
      default() {
        return [];
      }
    },
    type: {
      type: String,
      default: "add"
    }
  },
  components: {
    iconColorPicker
  },
  computed: {
    dialogTitle() {
      if (this.type === "put") {
        return "编辑分类";
      }
        return "新增分类";
    }

  },
  created() {

  },
  mounted() {
  },
  methods: {
    complete() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.type === "add" ? this.classifyInfo.classifyId = generateNanoid() : "";
          this.$emit("complete", this.classifyInfo);
          this.classifyInfo = {
            name: "",
            showIcon: "2",
            color: "#3e90fe"
          };
          return "";
        }
          console.log("error submit!!");
          return false;
      });
    },
    openCallBack() {
      if (this.type === "put") {
        this.classifyInfo = cloneDeep(this.info);
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.template-select{
  width: 100%;
}
/deep/ .el-form .el-form-item__label{
  text-align: left;
}
</style>
