<template>
  <div class="define-home">
    <menuDefine v-if="showDefineMenu" @close="closeMenuDefine" @complete="menuDefineComplete" :menu-datas="menuData"> </menuDefine>
    <home-classify-edit v-if="showEditClassifyMenu" :menu-data="editClassifyMenu" @close="closeEditClassifyMenu" @complete="editClassifyMenuComplete"> </home-classify-edit>
    <en-top-menu :need-back="true" menu-name="首页自定义" back-url="/home/index">
      <!--      <div slot="center" style="height: 35px;margin-top: 13px">-->
      <!--        <en-button @click="changeTheme('blue', '#599ccd')">-->
      <!--          切换至蓝色-->
      <!--        </en-button>-->
      <!--        <en-button @click="changeTheme('green', '#3eb9a9')">-->
      <!--          切换至绿色-->
      <!--        </en-button>-->
      <!--        <en-button @click="changeTheme('brown', '#9a8185')">-->
      <!--          切换至棕色-->
      <!--        </en-button>-->
      <!--        <en-button @click="changeTheme('white', '#ffffff')">-->
      <!--          切换至百色-->
      <!--        </en-button>-->
      <!--        <en-button @click="changeTheme('default', '')">-->
      <!--          切换至默认-->
      <!--        </en-button>-->
      <!--        <en-button @click="changeTheme('define', '#FF7300')">-->
      <!--          自定义-->
      <!--        </en-button>-->
      <!--      </div>-->
      <div slot="right">
        <en-button @click="saveChange">
          保存
        </en-button>
      </div>
    </en-top-menu>
    <div class="main-content">
      <!-- 左侧菜单 -->
      <div class="left-menu" :class="themeData.theme" :style="{ 'background-color': themeData.menuColor }">
        
        <en-button @click="clickDefineMenu" style="margin-left: 10px;" class="title-add-btn">
          <span class="iconfont addicon">&#xe602;</span>
          自定义菜单
        </en-button>
        <div v-for="(item, index) in menuList" :key="index" class="left-menu-item" @click="toggleItem(item)">
          <div class="left-menu-item-name" :class="{ open: item.open, disable: item.disable }" @dragstart="dragStart($event, item)" @dragend="dragend" :draggable="!item.disable">
            <en-icon
              class="left-menu-item-icon"
              size="12px"
              :style="{
                color: themeData.theme === 'white' ? iconReplaceBg(item.data.icon) : ''
              }"
              :name="item.data.customIcon"
            >
            </en-icon>
            <div class="left-menu-item-label">
              {{ item.data.name }}
            </div>
            <en-icon v-if="item.showChidren" class="left-menu-item-details" size="12px" name="fanhui"> </en-icon>
          </div>
          <template v-if="item.showChidren && item.open">
            <div v-for="(item1, index1) in item.dataList" :key="index + '-' + index1" class="left-menu-item" style="margin-left: 15px;margin-right: 0;" @click.stop="toggleItem(item1)">
              <div class="left-menu-item-name" :class="{ open: item1.open, disable: item1.disable }" @dragstart="dragStart($event, item1)" @dragend="dragend" :draggable="item1.disable ? false : true">
                <en-icon class="left-menu-item-icon" size="12px" :name="setChildrenIcon(item1.data.customIcon)"> </en-icon>
                <div class="left-menu-item-label">
                  {{ item1.data.name }}
                </div>
                <en-icon v-if="item1.showChidren" class="left-menu-item-details" size="12px" name="fanhui"> </en-icon>
              </div>
              <template v-if="item1.showChidren && item1.open">
                <div
                  v-for="(item2, index2) in item1.dataList"
                  :key="index + '-' + index1 + '-' + index2"
                  class="left-menu-item"
                  style="margin-left: 15px;margin-right: 0;"
                  @click.stop="toggleItem(item2)"
                >
                  <div
                    class="left-menu-item-name"
                    :class="{ open: item2.open, disable: item2.disable }"
                    @dragstart="dragStart($event, item2)"
                    @dragend="dragend"
                    :draggable="item2.disable ? false : true"
                  >
                    <en-icon class="left-menu-item-icon" size="12px" :name="`${setChildrenIcon(item2.data.customIcon)}`"> </en-icon>
                    <div class="left-menu-item-label">
                      {{ item2.data.name }}
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
      <!-- 右侧内容区域 -->
      <div ref="data" class="main-container" @dragover="dragOver" @drop="dragDrop" @dragenter="dragenter" @dragleave="dragleave" :class="dragData ? 'dragStyle' : ''">
        <grid-layout
          ref="layout"
          :layout.sync="dataList"
          style="width: 100%"
          :col-num="12"
          :row-height="80"
          :is-draggable="true"
          :is-resizable="true"
          :is-mirrored="false"
          :vertical-compact="true"
          :margin="[8,8]"
          :use-css-transforms="true"
        >
          <grid-item
            v-for="item in dataList"
            :key="item.i"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            @resize="resizeEvent"
            @move="moveEvent"
            @resized="reSizedEvent"
            @moved="movedEvent"
          >
            <card-define
              v-if="item.data"
              style="height:100%;width:100%"
              :key="item.i"
              :edit="true"
              :menu-data="item.data"
              :resized="resized"
              @delete="deleteItem(item)"
              @editItem="editItem(item)"
              @chart13style="chart13style(item)"
              @startEdit="editClassifyMenuStart"
              @change="change"
            >
            </card-define>
          </grid-item>
        </grid-layout>
      </div>
    </div>
    <chart-style-dialog v-if="chartStyleDialogflg" :show="chartStyleDialogflg" :data="chart13styleData" @close="chartStyleDialogClose"></chart-style-dialog>
    <header-style-dialog v-if="headStyleShow" :show="headStyleShow" :data="itemDdata" :allData="dataList" :headerStyle="headStyleData" @close="headStyleDialogClose" @changeCoutData="changeMenu"></header-style-dialog>

  </div>
</template>

<script>
import { mapMutations } from "vuex";
import VueGridLayout from "vue-grid-layout";
import { cloneDeep } from "lodash";
import { request } from "en-js";

import API from "@/api/homeMenu";
import {
 styleSize, menuClassifyTypeEnum, classifyMenuData, menuFixedTypeEnum, menuDataTypeEnum
} from "@/api/homeMenu/handleMenu";

import cardDefine from "../components/card-define";
import menuDefine from "./components/menu-define";
import chartStyleDialog from "./components/chart-style-dialog";
import homeClassifyEdit from "./components/home-classify-edit";
import { iconReplaceBg } from "./components/iconCode";
// import { iconReplaceBg } from "./components/iconCode";
import headerStyleDialog from "../components/headerStyleDialog.vue";

export default {
  name: "DefineHome",
  components: {
    cardDefine,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    menuDefine,
    homeClassifyEdit,
    chartStyleDialog,
    headerStyleDialog
  },
  data() {
    return {
      itemDdata:'',
      cacheMenu: "",
      // 拖动的数据源
      dragData: null,
      index: 0,
      menuKey: 100,
      // 左侧菜单承载数组
      menuList: [],
      menuData: [],
      homeData: {},
      dataList: [],
      showDefineMenu: false,
      themeData: {},
      editClassifyMenu: null,
      showEditClassifyMenu: false,
      handleMenu: null,
      isEdited: false,
      dragStyle: false,
      chartStyleDialogflg: false, // 指标卡弹框
      chart13styleData: {},
      resized: false,
      headStyleShow: false,
      headStyleData: {},
      editHeaderData: {}
    };
  },
  computed: {
    setChildrenIcon() {
      return (icon)=>{
          if (icon.indexOf("bus-board-icon") > -1 || icon.indexOf("bus-ywmb-icon") > -1) {
          return `${icon}_1`;
        }
        return icon;
      }
    },
    menuDataList() {
        const menuList = this.$store.getters["main/menuList"];
        // console.log(JSON.stringify(menuList), "menuListmenuList");
        console.log('sssssmenuListmenuListmenuList',menuList);
      return menuList;
    }
  },
  watch: {
    menuDataList: {
      handler(newVal) {
        console.log(newVal,'newValnewValnewValnewVal');
        this.updateData(newVal);
        this.updateLeftMenuDisableData();
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.getThemeData();
  },
  methods: {
    ...mapMutations("main", ["setMenuData", "setThemeInfo"]),
    iconReplaceBg,
    updateLeftMenuDisableData() {
      this.menuList.forEach((item) => {
        if (!item.unableConfig) {
          item.disable = this.disableWithMenu(item.data, 0);
        }
        if (!item.dataList) {
          return;
        }
        item.dataList.forEach((item1) => {
          if (!item1.unableConfig) {
            item1.disable = this.disableWithMenu(item1.data, 1);
          }
          if (!item1.dataList) {
            return;
          }
          item1.dataList.forEach((item2) => {
            item2.disable = this.disableWithMenu(item2.data, 2);
          });
        });
      });
    },
    disableWithMenu(menu, level = 0) {
      const datas = this.dataList.filter((item) => {
        const data = item.data;
        if (level === 0) {
          return menu.id === data.id || menu.id === data.refId;
        }
        if (level === 1) {
          if (menu.code && data.code) {
            return menu.code === data.code;
          }
          return menu.id === data.id || menu.id === data.refId;
        }
        return menu.dataType === data.dataType && data.id === menu.id;
      });
      return datas.length > 0;
    },
    /**
     * @description 切换皮肤套件
     */
    changeTheme(themeType, colorInfo) {
      const obj = {
        theme: themeType, // blue/green/brown/white/default/define(自定义)
        menuColor: colorInfo, // 颜色值用十六进制，不要用RGB
        contentColor: "contentColor", // 颜色值用十六进制，不要用RGB
        topMenuColor: "topMenuColor" // 颜色值用十六进制，不要用RGB
      };
      this.themeData = obj;
      this.setThemeInfo(obj);
    },
    getThemeData() {
      this.themeData = cloneDeep(this.$store.getters["main/themeInfo"]);
      this.themeData.theme = "default";
      this.themeData.menuColor = "default";
    },
    clickDefineMenu() {
      this.showDefineMenu = true;
    },
    closeMenuDefine() {
      this.showDefineMenu = false;
    },
    /* eslint-disable */
    @request(true, undefined, {
      subTitle: "正在处理..."
    })
    async menuDefineComplete(menuDatas) {
      this.cacheMenu = JSON.parse(JSON.stringify(menuDatas));
      const renderTree = [this.menuList[0].data, ...menuDatas];
      console.log('updateDataupdateDataupdateData2222');
      this.updateData(renderTree, true, false,1);
      this.updateLeftMenuDisableData();
      // this.menuKey++;
      // const datas = await API.saveCustomMenu(menuDatas);
      // this.setMenuData(datas);
      // this.updateData(datas, true, false);
      // this.$message("保存成功");
      this.showDefineMenu = false;
    },
    /* eslint-enable */
    /**
     * @description 开始拖拽，记录数据
     * @param event
     * @param dataInfo
     */
    dragStart(event, dataInfo) {
        // console.log(event, dataInfo, "event, dataInfo");
      this.index += 1;
      const data = dataInfo.data;
      if (data.dataList?.length) {
        data.dataList.forEach((item, i) => {
          if (i <= 7) {
            item.isCommon = "000";
          } else {
            item.isCommon = "001";
          }
        });
      }
      const pointString = styleSize(data);
      const points = pointString.split(",");
      this.dragData = {
        x: 0,
        y: 0,
        placeholderH: 0.5,
        w: Number(points[2]),
        h: Number(points[3]),
        i: String(this.index),
        _layerX: event.layerX,
        data: cloneDeep(data)
      };
    },
    dragend() {
      this.updateLocationData();
      this.dragData = null;
    },
    /**
     * @description 拖拽结束，阻止默认事件
     * @param event
     */
    dragOver(event) {
      event.preventDefault();
      const width = this.$refs.data.clientWidth / 12;
      const x = Math.floor((event.layerX - this.dragData._layerX) / width);
      const y = Math.floor((event.offsetY + this.$refs.data.scrollTop) / 80);
      if (this.dragData.x === x && this.dragData.y === y) {
        return;
      }
      this.dragData.x = x;
      this.dragData.y = y;
      this.dragData.minX = 0;
      this.dataList.forEach((item) => {
        let itemY = item.y;
        itemY -= item.addY ? item.addY : 0;
        const midH = item.h / 2;
        const maxY = itemY + midH;
        const maxX = item.x + item.w;
        if (maxY >= this.dragData.y) {
          if (this.dragData.y >= itemY && this.dragData.y <= midH && ((maxX <= this.dragData.x && item.x + item.w + this.dragData.w <= 12) || item.x >= this.dragData.x + this.dragData.w)) {
            item.addY = 0;
            const minX = this.dragData.minX;
            this.dragData.minX = Math.max([item.x + item.w, minX]);
          } else {
            itemY += this.dragData.placeholderH;
            item.addY = this.dragData.placeholderH;
          }
        } else {
          item.addY = 0;
        }
        item.y = itemY;
      });
    },
    /**
     * @description 松开鼠标，开始数据整理
     */
    dragDrop() {
      this.dataList.forEach((item) => {
        let itemY = item.y;
        itemY -= item.addY ? item.addY : 0;
        const maxY = itemY + item.h / 2;
        if (maxY >= this.dragData.y) {
          item.y += this.dragData.h;
        }
        item.addY = 0;
      });
      const x = 12 - (this.dragData.w + this.dragData.x);
      if (x < 0) {
        this.dragData.x += x;
      }
      this.dragData.data?.dataList?.forEach((cItem) => {
        cItem.isCommon = "000";
      });
      // console.log(this.dragData, "this.dragData");
      this.dataList.push(this.dragData);
      this.change();
      this.updateLeftMenuDisableData();
    },
    dragenter() {
      // console.log("dragenter");
    },
    dragleave() {
      this.updateLocationData();
      this.dragData.x = 0;
      this.dragData.y = 0;
    },
    updateLocationData() {
      this.dataList.forEach((item) => {
        item.x = item.x < 0 ? 0 : item.x;
        const y = item.y - (item.addY ? item.addY : 0);
        item.y = y > 0 ? y : 0;
        item.addY = 0;
      });
    },
    pointData(i) {
      const data = this.dataList.filter((item) => item.i === String(i))[0];
      return data;
    },
    /**
     * @description 开始拖拽调整尺寸
     * @param i
     * @param newH
     * @param newW
     */
    resizeEvent(i, newH, newW) {
      this.change();
      this.handleMenu = this.pointData(i);
      this.handleMenu.w = newW;
      this.handleMenu.h = newH;
    },
    /**
     * @description 开始移动位置
     * @param i
     * @param newX
     * @param newY
     */
    moveEvent(i, newX, newY) {
      this.handleMenu = this.pointData(i);
      this.handleMenu.x = newX;
      this.handleMenu.y = newY;
    },
    /**
     * @description 拖拽调整尺寸结束
     * @param i
     * @param newH
     * @param newW
     */
    reSizedEvent(i, newH, newW) {
      this.resized = !this.resized;
      this.handleMenu.w = newW;
      this.handleMenu.h = newH;
    },
    /**
     * @description 移动位置结束
     * @param i
     * @param newX
     * @param newY
     */
    movedEvent(i, newX, newY) {
      this.change();
      this.handleMenu.x = newX;
      this.handleMenu.y = newY;
    },
    /**
     * @description 点击删除图标
     * @param res
     */
    deleteItem(res) {
      const _index = this.dataList.indexOf(res);
      this.dataList.splice(_index, 1);

      this.updateLeftMenuDisableData();
    },
    /**
     * @description 自定义导航被修改
     * @param res
     */
    changeMenu(res){
      this.dataList.forEach((item)=>{
        if(item.data.id === res.id){
          item.data = res;
        }
      })
    },
    /**
     * @description 点击修改主题图标
     * @param res
     */
    editItem(res) {
      console.log(res,'点击');
        this.itemDdata = JSON.parse(JSON.stringify(res.data))
        this.headStyleShow = true;
        this.editHeaderData = res;
				if(res.data.headerStyle){
					this.headStyleData = JSON.parse(res.data.headerStyle)
				}else{
					this.headStyleData =  {}
				}

    },
    /**
     * @description 展开/收起菜单栏
     * @param itemData 对应踩烂数据
     */
    toggleItem(itemData) {
      if (itemData.showChidren === false) {
        return;
      }
      if (!itemData.open) {
        this.$set(itemData, "open", true);
      } else {
        itemData.open = !itemData.open;
      }
    },
    /**
     * @description 保存更改
     */
    /* eslint-disable */
    @request(true, undefined, {
      subTitle: "正在处理..."
    })
    async saveChange() {
      // 保存分类常用
      console.log(this.dataList,'保存前',this.homeData.dataList);
      await this.dataList.forEach((item) => {
        if (item?.data?.dataList) {
          const selectLen = item.data.dataList.filter((fItem) => fItem.isCommon === "000").length;
          if (selectLen > 8) {
            let curIndex = 0;
            item.data.dataList.forEach((cItem) => {
              cItem.isCommon === "000" && curIndex < 8 ? curIndex++ : (cItem.isCommon = "001"); // 只取前8项保存显示
            });
          }
          // console.log('selectLenselectLenselectLen', item.data);
          API.saveHomeData(item.data);
        }
      });

      const menuData = cloneDeep(this.homeData);
	  // console.log(menuData,'哪里的参数')
      menuData.dataList = [];
      this.homeData.dataList.forEach((item) => {
        const data = cloneDeep(item.data);
        if (!data.dataType) {
          data.dataType = 2;
        }
        data.refId = data.refId ? data.refId : data.id;
        data.styleSize = `${item.x},${item.y},${item.w},${item.h}`;
        data.isCommon = "000";
        delete data.dataList;
        menuData.dataList.push(data);
      });
      
      // 保存首页显示模块
      if (!this.cacheMenu?.length) {
        const arr = cloneDeep(this.menuData);
        this.cacheMenu = arr.filter((item) => item.type !== 0);
      }
      this.cacheMenu.forEach((item) => {
        delete item.subsetList;
        menuData.dataList?.forEach(it=>{
          if(it?.refId === item?.id){
            item.name = it.name;
          }
        })
      });
      // console.log(menuData, '保存的参数');
      await API.saveMenu([menuData, ...this.cacheMenu]);
      this.isEdited = false;
      // 获取最新菜单数据
      const menuDatas = await API.getMenu();
      // console.log(menuDatas, "menuDatas");
      this.setMenuData(menuDatas);
      console.log('updateDataupdateDataupdateData');
      this.updateData(menuDatas, false, true,2);
      this.$message("保存成功");

      this.$router.push({ path: "/home/index" });
    },
    /* eslint-disable */
    /*
     *处理左边菜单区域数据
     */
    getLeftMenuData(data) {
      const array = [];
      data = data.filter((item) => item.isShow === 0);
      data.forEach((item1, index) => {
        const menuItem = {
          data: item1,
          dataList: [],
          showChidren: false,
          open: false
        };
        menuItem.disable = false;
        menuItem.unableConfig = false;
        // 首页、我的事项、帮助中心、智能看板不能配置到首页
        if ([menuClassifyTypeEnum.home, menuClassifyTypeEnum.myMatter, menuClassifyTypeEnum.helpCenter, menuClassifyTypeEnum.kanBan].indexOf(item1.customType) !== -1) {
          menuItem.disable = true;
          menuItem.unableConfig = true;
        } else if (item1?.dataList?.length === 0 && item1.customType !== menuClassifyTypeEnum.report) {
          // 报表需要可以自定义设置
          // 没数据的隐藏掉
          return;
        }
        item1?.dataList?.forEach((item2) => {
          const itemData2 = {
            data: item2,
            showChidren: false,
            open: false
          };
          if (
            item2.dataType === menuDataTypeEnum.business
            || item2.dataType === menuDataTypeEnum.templateList
            || item2.dataType === menuDataTypeEnum.kanBanTemplate
            || item2.dataType === menuDataTypeEnum.kanBan
          ) {
            itemData2.disable = false;
            itemData2.unableConfig = false;
          } else if (item2.dataType === menuDataTypeEnum.fixed) {
            if (
              item2.code === menuFixedTypeEnum.calendar
              || item2.code === menuFixedTypeEnum.attendanceSignIn
              || item2.code === menuFixedTypeEnum.report
              || item2.code === menuFixedTypeEnum.chat
              || item2.code === menuFixedTypeEnum.myTrip
              || item2.code === menuFixedTypeEnum.notice
              || item2.code === menuFixedTypeEnum.myOrder
              || item2.code === menuFixedTypeEnum.finishedTasks
              || item2.code === menuFixedTypeEnum.gtasks
              || item2.code === menuFixedTypeEnum.myBusiness
              || item2.code === menuFixedTypeEnum.documentManagement
              || item2.code === menuFixedTypeEnum.wallet
              || item2.code === menuFixedTypeEnum.newAddArticle
              || item2.code === menuFixedTypeEnum.mail
              || item2.code === menuFixedTypeEnum.myFolder
            ) {
              itemData2.disable = false;
              itemData2.unableConfig = false;
            } else {
              itemData2.disable = true;
              itemData2.unableConfig = true;
            }
          } else if (item2.dataType === menuDataTypeEnum.outLink) {
            itemData2.disable = true;
            itemData2.unableConfig = true;
          } else {
            itemData2.disable = false;
            itemData2.unableConfig = false;
          }
          if (item2?.dataList?.length > 0) {
            itemData2.showChidren = true;
            itemData2.dataList = [];
            item2.dataList?.forEach((item3) => {
              const itemData3 = {
                data: item3,
                showChidren: false,
                open: false
              };
              itemData2.dataList.push(itemData3);
            });
          }
          menuItem.dataList.push(itemData2);
        });
        // 首页、智能看板和dataList没数据的不展示下一级
        if (
          item1.customType === menuClassifyTypeEnum.home
          || item1.dataList?.length === 0
          || item1.customType === menuClassifyTypeEnum.kanBan
          || item1.customType === menuClassifyTypeEnum.myMatter
          || item1.customType === menuClassifyTypeEnum.helpCenter
        ) {
          menuItem.showChidren = false;
        } else {
          menuItem.showChidren = true;
        }
        if (item1.customType === menuClassifyTypeEnum.report) {
          // 报表需要可以自定义拖拽
          menuItem.disable = false;
        }
        menuItem.id = index;
        array.push(menuItem);
      });
      // console.log(array);
      return array;
    },
    /*
     *处理右边区域数据
     */
    getRightData(data) {
      const homeData = cloneDeep(data);
      const dataList = [];
      // console.log(homeData);
      if (homeData.dataList) {
        homeData.dataList.forEach((item, index) => {
          const pointData = {
            x: 0,
            y: 0,
            w: 5,
            h: 5,
            i: String(index + 1),
            data: item
          };
          const points = item.styleSize.split(",");
          if (points.length === 4) {
            pointData.x = Number(points[0]);
            pointData.y = Number(points[1]);
            pointData.w = Number(points[2]);
            pointData.h = Number(points[3]);
          }
          const menuData = classifyMenuData(item, this.menuData);
          if (menuData.dataList) {
            item.dataList = cloneDeep(menuData.dataList);
          }
          dataList.push(pointData);
        });
      }
      this.index = dataList.length + 10;
      homeData.dataList = dataList;
      return homeData;
    },
    /**
     * 菜单数据
     */
    updateData(menuList, isUpdateMenu = true, isUpdateHome = true,type) {
      console.log(menuList,'menuListmenuListmenuListmenuList',type);
      this.menuData = menuList;
      menuList = cloneDeep(menuList);
      if (isUpdateMenu) {
        this.menuList = this.getLeftMenuData(cloneDeep(menuList));
      }
      if (isUpdateHome) {
        const homeData = this.getRightData(menuList.filter((item) => item.type === 0)[0]);
        this.homeData = homeData;

        this.dataList = this.homeData.dataList;
      }
    },
    closeEditClassifyMenu() {
      this.showEditClassifyMenu = false;
    },
    editClassifyMenuStart(menu) {
      this.showEditClassifyMenu = true;
      this.editClassifyMenu = menu;
    },
    change() {
      this.isEdited = true;
    },
    editClassifyMenuComplete(dataList) {
      this.showEditClassifyMenu = false;
      this.editClassifyMenu.dataList = dataList;
      this.editClassifyMenu = null;
      this.change();
    },
    editSuccess(md, v) {
      // console.log("home-define", v);
      // console.log(md, v);
    },
    //
    chart13style(item) {
      this.chart13styleData = item;
      this.chartStyleDialogflg = true;
      // console.log(item);
    },
    chartStyleDialogClose(obj) {
      // this.chart13styleData.data.customAttr = JSON.stringify(obj);
      // this.$set(this.chart13styleData.data, "customAttr", JSON.stringify(obj));
      this.chartStyleDialogflg = false;
    },
    headStyleDialogClose(obj) {
      console.log(obj, "obj");
      this.headStyleShow = false;
      const res = this.editHeaderData;
	    const dataList = JSON.parse(JSON.stringify(this.dataList))
      const _index = this.dataList.indexOf(res);
	    dataList[_index].data.headerStyle = JSON.stringify(obj);
	    this.dataList = dataList;
	    this.homeData.dataList = dataList
      console.log(this.homeData, "homedate");
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdited && to.path === "/home/index") {
      this.$confirm("您尚未保存，返回则不会保存改配置内容", "取消配置内容？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        next();
      });
    } else {
      next();
    }
  }
};
</script>
<style lang="scss" scoped>
.title-add-btn{
  display: flex;
  align-items: center;
}
.addicon{
  font-size: 12px;
}
.define-home /deep/ {
  height: 100%;
  background-color: #000000;
  position: relative;
  .en-top-menu {
    border-bottom: 1px solid #323e52;
    .en-top-menu-right{
      margin-right: 0px;
    }
  }

  .main-content {
    display: flex;
    justify-content: space-between;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
    height: calc(100% - 60px);
    .dragStyle {
      .vue-grid-layout {
        pointer-events: none;
      }
    }
    .left-menu {
      width: 190px;
      padding: 20px 0;
      height: 100%;
      overflow-y: auto;
      background-color: #232c3d;
      transition: 0.3s ease-in-out background-color;

      .el-button {
        padding: 11px 15px;
        width: calc(100% - 20px);
      }

      /* 设置滚动条的样式 */
      &::-webkit-scrollbar {
        width: 0;
        display: block;
        transition: 0.3s ease-in-out opacity;
      }

      /* 滚动槽 */
      &::-webkit-scrollbar-track {
        border-radius: 6px;
      }

      /* 滚动条滑块 */
      &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background: #bdbdbf;
      }

      &::-webkit-scrollbar-thumb:window-inactive {
        background: #bdbdbf;
      }

      .left-menu-item {
        /*width: calc(100% - 10px);*/
        margin: 15px 10px 0 10px;
        // 常规菜单
        &.normal-menu {
          .left-menu-item-name {
            background: none !important;
            color: rgba(255, 255, 255, 0.6) !important;

            &:hover {
              color: #ffffff !important;
              border: none;
              padding: 11px 10px;
            }
          }
        }

        .left-menu-item-name {
          background-color: #333d57;
          -webkit-border-radius: 4px 4px 4px;
          -moz-border-radius: 4px 4px 4px;
          border-radius: 4px 4px 4px;
          display: flex;
          justify-content: flex-start;
          flex-wrap: nowrap;
          font-size: 14px;
          color: #a9b5c6;
          padding: 11px 10px;
          cursor: pointer;
          position: relative;
          .left-menu-item-icon {
            margin-right: 10px;
          }
          .left-menu-item-label {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .left-menu-item-details {
            position: absolute;
            right: 10px;
            transform: rotate(180deg);
            transition: 0.3s ease-in-out all;
          }

          &:hover,
          &.open {
            border-left: 2px solid #3e90fe;
            padding: 11px 10px 11px 8px;
            -webkit-border-radius: 0 4px 4px 0;
            -moz-border-radius: 0 4px 4px 0;
            border-radius: 0 4px 4px 0;
            color: #ffffff;
          }

          &.open {
            .left-menu-item-details {
              transform: rotate(270deg);
            }
          }

          &.normal-menu {
            background-color: transparent;

            &:hover {
              border-left: none;
              -webkit-border-radius: 4px 4px 4px;
              -moz-border-radius: 4px 4px 4px;
              border-radius: 4px 4px 4px;
            }
          }
        }
        .disable {
          cursor: not-allowed;
        }
      }
    }
    .main-container {
      width: calc(100% - 170px);
      height: 100%;
      overflow-y: auto;
      /* 设置滚动条的样式 */
      &::-webkit-scrollbar {
        width: 0;
      }

      /* 滚动槽 */
      &::-webkit-scrollbar-track {
        border-radius: 6px;
      }

      /* 滚动条滑块 */
      &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background: #bdbdbf;
      }

      &::-webkit-scrollbar-thumb:window-inactive {
        background: #bdbdbf;
      }

      .card-define {
        height: 100%;
        margin-top: 0;

        .card-define-body {
          height: calc(100% - 45px);
          overflow-y: auto;
          /* 设置滚动条的样式 */
          &::-webkit-scrollbar {
            width: 6px;
          }

          /* 滚动槽 */
          &::-webkit-scrollbar-track {
            border-radius: 6px;
          }

          /* 滚动条滑块 */
          &::-webkit-scrollbar-thumb {
            border-radius: 6px;
            background: #bdbdbf;
          }

          &::-webkit-scrollbar-thumb:window-inactive {
            background: #bdbdbf;
          }
        }
      }
    }

    // 白色皮肤
    &.white {
      .left-menu-item {
        .left-menu-item-name {
          background-color: #ecf4fc;
          color: #333333;

          &:hover,
          &.open {
            background-color: #daecf9;

            .left-menu-item-details {
              color: #3e90fe;
            }
          }
        }

        .left-menu-item-children {
          .left-menu-children-item {
            background-color: #ecf4fc;
            color: #333333;

            &:hover {
              background-color: #daecf9;
            }
          }
        }

        &.normal-menu {
          .left-menu-item-name {
            color: rgba(51, 51, 51, 0.6) !important;

            &:hover {
              color: #333333 !important;
            }
          }
        }
      }
    }

    // 蓝色皮肤
    &.blue {
      .el-button {
        background-color: rgb(147, 191, 222);
      }

      .left-menu-item {
        .left-menu-item-name {
          background-color: #6ea8d3;
          color: #ffffff;

          &:hover,
          &.open {
            background-color: rgb(139, 186, 220);
            border-color: #ffffff;
          }
        }
      }

      .left-menu-item-children {
        .left-menu-children-item {
          background-color: #6ea8d3;
          color: #ffffff;

          &:hover {
            background-color: rgb(139, 186, 220);
            border-color: #ffffff;
          }
        }
      }
    }

    // 绿色皮肤
    &.green {
      .el-button {
        background-color: rgb(129, 209, 199);
        border-color: rgb(129, 209, 199);
      }

      .left-menu-item {
        .left-menu-item-name {
          background-color: rgb(97, 196, 185);
          color: #ffffff;

          &:hover,
          &.open {
            background-color: rgb(120, 206, 195);
            border-color: #ffffff;
          }
        }

        .left-menu-item-children {
          .left-menu-children-item {
            background-color: rgb(97, 196, 185);
            color: #ffffff;

            &:hover {
              background-color: rgb(120, 206, 195);
              border-color: #ffffff;
            }
          }
        }
      }
    }

    // 棕色皮肤
    &.brown {
      .el-button {
        background-color: rgb(189, 173, 176);
        border-color: rgb(189, 173, 176);
      }

      .left-menu-item {
        .left-menu-item-name {
          background-color: rgb(166, 145, 149);
          color: #ffffff;

          &:hover,
          &.open {
            background-color: rgb(184, 167, 170);
            border-color: #ffffff;
          }
        }
      }

      .left-menu-item-children {
        .left-menu-children-item {
          background-color: rgb(166, 145, 149);
          color: #ffffff;

          &:hover {
            background-color: rgb(184, 167, 170);
            border-color: #ffffff;
          }
        }
      }
    }

    // 自定义皮肤套件公共样式处理
    &.define,
    &.default {
      .el-button {
        background-color: rgba(255, 255, 255, 0.35);
        border-color: rgba(255, 255, 255, 0.35);
      }

      .left-menu-item {
        .left-menu-item-name {
          background: rgba(236, 241, 247, 0.14);
          color: #ffffff;

          &:hover,
          &.open {
            background-color: rgba(255, 255, 255, 0.3);
            border-color: #ffffff;
          }
        }
      }

      .left-menu-item-children {
        .left-menu-children-item {
          background: rgba(236, 241, 247, 0.14);
          color: #ffffff;

          &:hover {
            background-color: rgba(255, 255, 255, 0.3);
            border-color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
