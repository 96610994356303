<!--
 * @Author: wuqi
 * @Date: 2021-12-28 11:03:15
 * @LastEditors: wuqi
 * @LastEditTime: 2021-12-28 11:08:38
 * @Description:
-->
<template>
  <div class='icon-text-wrap'>
    <en-icon
      v-bind="$attrs"
      v-on="$listeners"></en-icon>
    <span class='label' v-if="label">{{label}}</span>
  </div>
</template>

<script>
export default {
  name: "en-icon-text",
  props: {
    label: String
  },
  data() {
    return {
    };
  },
  computed: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style lang='scss' scoped>
.icon-text-wrap{
  display: flex;
  align-items: center;
  .label{
    margin-left: 10px;
  }
}
</style>
