<template>
  <div class="card-define" :style="{'background-color':bgStyle}">
    <!-- 分类  -->
    <home-classify-menu
      v-if="isClassifyData&&!isBusinessList"
      :menu-data="menuData"
      :edit="edit"
      @delete="deleteData"
      @editTheme='editTheme'
      @deleteCommonMenu="deleteCommonMenu"
      @startEdit="startEdit">
    </home-classify-menu>
    <!-- 编辑模式  -->
    <template v-else-if="edit">
        <!-- 看板模式 -->
      <chart
        :menu-data="menuData"
        @deleteData="deleteData"
        @chart13style="chart13style"
        :edit="true"
        v-if="iskanban"
        :item="menuData.refData"
        :type="menuData.refData.refType"
        isIndex="index"
        :id="menuData.refData.refId"
        :resized="resized"
      >
      </chart>
      <!-- <report-edit key="kanban"  :menu-data="menuData" @deleteData="deleteData" :edit="true"></report-edit> -->
      <component v-else :is="currentTabComponent" :classify-list="classifyList" :edit="true" @deleteData="deleteData" @editTheme='editTheme' :menu-data="menuData" :key="menuData.id"></component>

    </template>
    <!--  首页展示模块  -->
    <template v-else>
      <chart
        v-if="iskanban"
        :item="menuData.refData.refData"
        :type="menuData.refData.refData.refType"
        isIndex="index"
        :id="menuData.refData.refData.refId"
        :menu-data="menuData"
      >
      </chart>
      <component ref="componentWrap"  v-else :is="currentTabComponent" :classify-list="classifyList"  :key="menuData.id" :menu-data="menuData"
       ></component>
    </template>
  </div>
</template>

<script>
import { menuDataTypeEnum, menuFixedTypeEnum, menuClassifyTypeEnum } from "@/api/homeMenu/handleMenu";

export default {
  name: "CardDefine",
  // 模块异步路由,减少打包体积
  components: {
    myBusiness: () => import("../myEn/components/myBuss"),
    myOrder: () => import("../myEn/components/myOrder"),
    myTask: () => import("../myEn/components/myTask"),
    myRead: () => import("../myEn/components/myRead"),
    handle: () => import("../myEn/components/handle"),
    awitDo: () => import("../myEn/components/awitDo"),
    homeClassifyMenu: () => import("./home-classify-menu"),
    homeAtt: () => import("../../hr/attendance/homeAttendance/index"),
    calendarEdit: () => import("./calendar-edit"),
    otherMenuEdit: () => import("./other-menu-edit"),
    attendanceSignEdit: () => import("./attendance-sign-edit"),
    reportEdit: () => import("./report-edit"),
    chart: () => import("@/views/businessChart/components/chart.vue"),
    DocsAdmin: () => import("./docsAdmin"),
    ChatlistEdit: () => import("./chat-list-edit"),
    MyFolder: () => import("./my-folder"),
    BusinessList: () => import("./business-list"),
    ReportComponents: () => import("./report-components"),
    myTrip: () => import("./myTrip-index-mod"),
    themeMode: () => import("./theme-mode"),
    mailBox: () => import("./mail-box")
  },
  props: {
    menuData: {
      type: Object
    },
    classifyList: {
      type: Array,
      default() {
        return [];
      }
    },
    edit: {
      type: Boolean,
      default: false
    },
    resized: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    menuData(newValue) {
      // console.log(newValue, "newValue");
    }
  },
  computed: {
    bgStyle() {
      // console.log(this.menuData.headerStyle, "this.menuData.headerStyle");
      if (this.menuData.headerStyle) {
        return JSON.parse(this.menuData.headerStyle).bgStyle;
      }
      return "#FFFFFF";
    },
    title() {
      // console.log(this.menuData, "this.menuData");
      return this.menuData.name;
    },
    isClassifyData() {
      return this.menuData?.dataList?.length > 0;
    },
    dataType() {
       return this.menuData?.dataType;
    },
    isBusinessList() {
      return [menuDataTypeEnum.templateList, menuDataTypeEnum.business, menuDataTypeEnum.basic, menuDataTypeEnum.task].includes(this.dataType) && !this.isClassifyData;
    },
    menuDataTypeEnum() {
      return menuDataTypeEnum;
    },
    menuFixedTypeEnum() {
      return menuFixedTypeEnum;
    },
    codeOrIcon() {
      return this.menuData?.code || this.menuData?.icon;
    },
    isBusiness() {
      return this.menuData?.customType === menuClassifyTypeEnum.businessModeling || this.menuData?.refData;
    },
    iskanban() {
      return this.menuData?.dataType === menuDataTypeEnum.kanBanTemplate
        || this.menuData?.dataType === menuDataTypeEnum.kanBan;
    },
    isThemeMode() {
      return this.menuData?.dataType === menuDataTypeEnum.theme;
    },
    currentTabComponent() {
      let result = "otherMenuEdit";
      // console.log(this.codeOrIcon, "this.codeOrIcon");
      switch (this.codeOrIcon) {
        case menuFixedTypeEnum.calendar:
          result = "calendarEdit";
          break;
        case menuFixedTypeEnum.attendanceSignIn:
          result = this.edit ? "attendanceSignEdit" : "homeAtt";
          break;
        case menuFixedTypeEnum.report:
          result = "reportEdit";
          break;
        case menuFixedTypeEnum.documentManagement:
          result = "DocsAdmin";
          break;
        case menuFixedTypeEnum.chat:
          result = "ChatlistEdit";
          break;
        case menuFixedTypeEnum.myFolder:
          result = "MyFolder";
          break;
        case menuFixedTypeEnum.myBusiness:
          result = "myBusiness";
          break;
        // case menuFixedTypeEnum.myNotice:
        //   result = "myNotice";
        //   break;
        case menuFixedTypeEnum.myOrder:
          result = "myOrder";
          break;
        case menuFixedTypeEnum.myTask:
          result = "myTask";
          break;
        case menuFixedTypeEnum.notice:
          result = "myRead";
          break;
        case menuFixedTypeEnum.gtasks:
          result = "awitDo";
          break;
        case menuFixedTypeEnum.finishedTasks:
          result = "handle";
          break;
        case menuFixedTypeEnum.templateKanBan:
          result = "chart";
          break;
        case menuFixedTypeEnum.businessList:
          result = "BusinessList";
          break;
        case menuFixedTypeEnum.theme:
          result = "themeMode";
          break;
        case menuFixedTypeEnum.mail:
          result = "mailBox";
          break;
        // 我的行程
        case menuFixedTypeEnum.myTrip:
          result = "myTrip";
          break;
        // 报表
        case "2_17":
          result = "ReportComponents";
          break;
        default:
          if (this.iskanban) {
            result = this.isBusiness ? "chart" : result;
          } else if (this.isThemeMode) {
            result = "themeMode";
          } else {
            // this.menuData?.icon === "2_17" ? result = "ReportComponents" : "";
            result = this.isBusinessList ? "BusinessList" : result;
          }
          break;
      }
      return result;
    }
  },
  mounted() {
    // console.log(this.menuData, "card-define");
    // console.log(this.classifyList, "cccc");
  },
  methods: {
    /**
     * @description 删除指定的模块
     */
    deleteData() {
      this.$confirm("删除此应用，将不展示在首页模块中", "删除此应用？", {
        type: "warning",
        showCancelButton: false
      }).then(() => {
        this.$emit("delete", this.menuData);
        this.$emit("change");
      });
    },
    editTheme() {
        // console.log(this.menuData, "this.define传递到首页的数据");
        console.log("editTheme");
        this.$emit("editItem", this.menuData);
        this.$emit("change");
    },
    chart13style() {
      this.$emit("chart13style", this.menuData);
      this.$emit("change");
    },
    deleteCommonMenu(menu) {
      this.$confirm("删除此应用，将不展示在首页模块中", "删除此应用？", {
        type: "warning",
        showCancelButton: false
      }).then(() => {
        menu.isCommon = "001";
        this.$emit("change");
      });
    },
    change() {
      this.$emit("change");
    },
    startEdit() {
      this.$emit("startEdit", this.menuData);
    },
    editSuccess(v) {
      console.log("card-define", v);
      this.$emit("editSuccess", v);
    }
  }
};
</script>

<style lang="scss" scoped>
  .card-define {
    height: 100%;
    width: 100%;
    // background-color: #ffffff;
    -webkit-border-radius: 4px 4px 4px;
    -moz-border-radius: 4px 4px 4px;
    border-radius: 4px 4px 4px;
    margin-top: 10px;
    overflow: hidden;
  }
</style>
