var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-define", style: { "background-color": _vm.bgStyle } },
    [
      _vm.isClassifyData && !_vm.isBusinessList
        ? _c("home-classify-menu", {
            attrs: { "menu-data": _vm.menuData, edit: _vm.edit },
            on: {
              delete: _vm.deleteData,
              editTheme: _vm.editTheme,
              deleteCommonMenu: _vm.deleteCommonMenu,
              startEdit: _vm.startEdit,
            },
          })
        : _vm.edit
        ? [
            _vm.iskanban
              ? _c("chart", {
                  attrs: {
                    "menu-data": _vm.menuData,
                    edit: true,
                    item: _vm.menuData.refData,
                    type: _vm.menuData.refData.refType,
                    isIndex: "index",
                    id: _vm.menuData.refData.refId,
                    resized: _vm.resized,
                  },
                  on: {
                    deleteData: _vm.deleteData,
                    chart13style: _vm.chart13style,
                  },
                })
              : _c(_vm.currentTabComponent, {
                  key: _vm.menuData.id,
                  tag: "component",
                  attrs: {
                    "classify-list": _vm.classifyList,
                    edit: true,
                    "menu-data": _vm.menuData,
                  },
                  on: { deleteData: _vm.deleteData, editTheme: _vm.editTheme },
                }),
          ]
        : [
            _vm.iskanban
              ? _c("chart", {
                  attrs: {
                    item: _vm.menuData.refData.refData,
                    type: _vm.menuData.refData.refData.refType,
                    isIndex: "index",
                    id: _vm.menuData.refData.refData.refId,
                    "menu-data": _vm.menuData,
                  },
                })
              : _c(_vm.currentTabComponent, {
                  key: _vm.menuData.id,
                  ref: "componentWrap",
                  tag: "component",
                  attrs: {
                    "classify-list": _vm.classifyList,
                    "menu-data": _vm.menuData,
                  },
                }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }