<!--
 * @Author: Do not edit
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-29 19:23:49
 * @Description: Do not edit
 * @FilePath: \user\src\views\home\components\calendar-edit.vue
-->
<template>
  <div class="calendar-edit" :class="topCalendar">
    <div class="date-picker-title" v-if="!menuData">
      <en-icon name="fanhui" size="14px" @click.native="$router.push('/home/index')"> </en-icon>
      <div class="label">
        日历
      </div>
    </div>
    <div class="delete-button" v-else>
      <en-icon v-if="edit" name="shanchu-danchuang" size="12px" @click.native="deleteData"> </en-icon>
      <en-icon v-else @click.native="toInfo" class="header-right-arrow" name="zuocecaidanlan-shouqi" size="22px"></en-icon>
    </div>
    <en-calendar-picker :need-head="true" :other-month="false" :start-day="0"> </en-calendar-picker>
  </div>
</template>

<script>
export default {
  name: "calendar-edit",
  props: {
    menuData: {
      type: Object
    },
    edit: {
      type: Boolean
    }
  },
  computed: {
    topCalendar() {
      return {
        indexC: !!this.menuData
      };
    }
  },
  methods: {
    toInfo() {
      this.$router.push({
        path: "/home/calendar"
      });
    },
    deleteData() {
      this.$emit("deleteData");
    }
  }
};
</script>

<style lang="scss" scoped>
.calendar-edit {
  height: 100%;
  background: #fff;
  // display: flex;
  // /deep/ .en-calendar-picker-header {
  //   background-color: #FFFFFF;
  // }
  // /deep/ .en-calendar-picker-item {
  //   height: 50px !important;
  // }
  .date-picker-title {
    display: flex;
    justify-content: flex-start;
    background-color: #ffffff;
    // border-bottom: 1px solid #e8ecf2;
    padding: 0 20px;
    position: relative;
    line-height: 50px;
    align-items: center;

    .en-icon {
      color: rgb(169, 181, 198);
      margin-right: 10px;
      cursor: pointer;
    }

    .label {
      font-size: 14px;
      font-weight: bold;
      color: #333;
    }
  }
  .delete-button {
    position: absolute;
    right: 20px;
    height: 50px;
    line-height: 50px;
    color: #a9b5c6;
    .en-icon{
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
    }
  }
  /deep/ .en-calendar-picker {
    height: 100%;
  }
  /deep/ .en-calendar-picker-header {
    padding: 5px 20px;
    align-items: center;
    background: #F5F7FA;
  }
  /deep/ .en-calendar-picker .en-calendar-picker-body {
    height: calc(100% - 150px);
  }
  /deep/ .en-calendar-picker .en-calendar-picker-body .en-calendar-picker-item {
    height: auto;
  }
  /deep/ .en-calendar-picker .en-calendar-picker-header .en-icon {
    width: 16px;
    height: 16px;
    // margin-right: 10px;
    // margin-top: 12px;
    // margin-bottom: 0;
    color: rgb(169, 181, 198);
  }
  /deep/ .en-calendar-picker-header .label{
    margin-left: 5px;
  }
  /deep/ .en-calendar-picker .en-calendar-picker-header {
    line-height: 40px;
  }
}
.indexC {
  // bug37084
  /deep/ .en-calendar-picker {
    .en-calendar-picker-header{
      background: none;
    }
    .en-calendar-picker-week{
      background:none;
      box-shadow: none;
      .en-calendar-picker-week-item{
        border-right:0;
      }
    }
    .en-calendar-picker-body {
      height: calc(100% - 100px);
      .en-calendar-picker-item{
        border-right: 0;
        border-top:0;
        .label{
          right: calc(50% - 12px);
          top: 30%;
        }
      }
    }
  }
}
</style>
