var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "icon-text-wrap" },
    [
      _c(
        "en-icon",
        _vm._g(_vm._b({}, "en-icon", _vm.$attrs, false), _vm.$listeners)
      ),
      _vm.label
        ? _c("span", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }