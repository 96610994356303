<template>
  <div class="my-notice">
    <div class="my-notice-head">
      <en-icon
        name="fanhui"
        size="12px"
        @click.native="$router.push('/myEn')"
      >
      </en-icon>
      <div class="label">
        我的消息
      </div>
    </div>
    <div class="my-notice-body">
      <div
        v-for="item in myNotice.records"
        :key="item.id"
        class="my-notice-data"
      >
        <div class="my-notice-data-basic">
          <div
            class="my-notice-data-icon"
            :style="{'background-color':iconReplaceBg(item.code) }"
          >
            <en-icon
              name="shujuxiangqing"
            >
            </en-icon>
          </div>
          <div class="my-notice-data-name">
            {{ item.message }}
          </div>
        </div>
        <div class="my-notice-data-status">
          <div class="my-notice-data-date">
            {{ item.endTime }}
          </div>
          <el-tag>
            {{ item.billStatusName }}
          </el-tag>
        </div>
      </div>
    </div>
    <div
      v-if="myNotice.totalPage > 1"
      class="my-notice-more"
    >
      加载更多({{ myNotice.total - myNoticePage * 5 }})
    </div>
  </div>
</template>

<script>
import myNotice from "./data/myNotice";

export default {
  name: "MyNotice",
  data() {
    return {
      myNotice,
      myNoticePage: 1
    };
  }
};
</script>

<style lang="scss" scoped>
  .my-notice {
    width: 100%;
    margin-bottom: 10px;
    overflow-y: auto;

    .my-notice-head {
      display: flex;
      justify-content: flex-start;
      background-color: #ffffff;
      border-bottom: 1px solid #E8ECF2;
      padding: 0 20px;
      position: relative;
      line-height: 50px;

      .en-icon {
        color: #A9B5C6;
        margin-top: 18px;
        margin-right: 5px;
        cursor: pointer;
      }

      .label {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }
    }

    .my-notice-body {
      background-color: #ffffff;
      -webkit-border-radius: 0 0 4px 4px;
      -moz-border-radius: 0 0 4px 4px;
      border-radius: 0 0 4px 4px;
      width: 100%;
      padding: 0 10px;

      .my-notice-data {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 15px 10px;
        border-bottom: 1px solid #E8ECF2;

        .my-notice-data-basic {
          display: flex;
          justify-content: flex-start;

          .my-notice-data-icon {
            width: 40px;
            height: 40px;
            background-color: #26C393;
            color: #ffffff;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            padding: 11px 11px 11px;

            .en-icon {
              width: 18px;
              height: 18px;
            }
          }

          .my-notice-data-name {
            line-height: 40px;
            margin-left: 15px;
            font-size: 13px;
            font-weight: 400;
            color: #333333;
          }
        }

        .my-notice-data-status {
          text-align: right;

          .my-notice-data-date {
            color: #91A1B7;
            font-size: 12px;
            margin-bottom: 7px;
          }
        }
      }
    }

    .my-notice-more {
      color: #91A1B7;
      font-size: 12px;
      cursor: pointer;
      padding: 13px 0 14px;
      background-color: #ffffff;
    }
  }
</style>
