/**
 * @description 图标替换成现有系统中的图标名
 * @param iconCode
 */
function iconReplaceName(iconCode) {
  const map = {
    "0_1": "shouye", // 常用/首页",
    "1_1": "icon-ind-userdefined", // 用户自定义",
    "2_1": "shanglvguanli-shixin", // 商旅管理",
    "2_2": "bangongguanli", // 办公管理",
    "2_3": "gongjuyingyong", // 工具应用",
    "2_4": "pingzhengshengcheng-shixin", // 凭证管理",
    "2_5": "jiesuanzhongxin", // 结算中心"
    "2_6": "qitafenlei", // 其他分类",
    "2_7": "wodeen", // 我的河洛",
    "2_8": "icon-linkchat", // 沟通",
    "2_9": "icon-linkcomp", // 公司圈",
    "2_10": "zhinenghesuan", // 智能核算",
    "2_11": "newfont1 icon-indbmclassicon1", // 魔钥资源业务
    "2_12": "icon-indboard", // 智能看板
    "2_13": "", // 帮助中心
    "2_14": "", // 方案中心
    "2_15": "icon-indshuiwuguanli", // 税务管理
    "4_6": "iconfont icon-more", // 其他
    "001": "行程预订", // 分类
    "002": "iconfont icon-ind-initiate", // 企业沟通
    "003": "iconfont icon-matter", // 事项申请
    300: "iconfont icon-billicon", // 事项申请单应用
    "005": "wodehangcheng-xianxing", // 我的行程
    "006": "kaoqingguanli", // 考勤签到
    "007": "iconfont icon-ind-docmanage", // 文档管理
    "009": "iconfont icon-indbeover", // 申请单完结
    "010": "iconfont icon-query", // 报表查询
    "011": "iconfont icon-indpendtask", // 待办事项",
    "012": "iconfont icon-indunread", // "未读通知"
    "013": "iconfont icon-indhasread", // "已读通知"
    "014": "iconfont icon-indmybill", // "我的表单"
    "015": "wodedingdan-xianxing", // "我的订单"
    "016": "iconfont icon-inddonetask", // 已办任务",
    "017": "iconfont icon-ind-initiate", // 发起沟通
    "018": "iconfont icon-ind-personnel", // 人事信息
    "019": "iconfont icon-indTicket", // 我的票夹
    "021": "jipiaoyuding-xianxing", // 机票预订",
    "022": "jiudianyuding-xianxing", // 酒店预订",
    "023": "yongcheyuding-xianxing", // 用车预订",
    "024": "dingdanchaxun-xianxing", // 订单查询",
    "025": "iconfont icon-voubuild", // 凭证生成
    "026": "iconfont icon-vouhandle", // 凭证处理
    "027": "iconfont icon-vououtput", // 凭证输出
    "028": "iconfont icon-invoice-apply", // 开票申请
    "029": "iconfont icon-invoice-count", // 结算汇总
    "030": "iconfont icon-ind-objectadmin", // 业务管理
    "031": "iconfont icon-ind-interface", // 接口管理
    "032": "iconfont icon-ind-downcenter", // 下载中心
    "033": "iconfont icon-indcalendar", // 日历
    "034": "34、我的en",
    "035": "35、公司圈",
    360: "newfont1 icon-indbmclassicon",
    "036": "newfont1 icon-indbmicon",
    "037": "iconfont icon-areport",
    370: "iconfont icon-query",
    "038": "iconfont icon-ind-bussiness",
    "039": "iconfont icon-voubuild",
    "040": "iconfont icon-vouhandle",
    "041": "凭证输出",
    "042": "newfont1 icon-yuebiao",
    "043": "newfont1 icon-zongzhang",
    "044": "newfont1 icon-mingxizhang",
    "045": "newfont1 icon-fzyuebiao",
    "046": "newfont1 icon-fzmingxizhang",
    "047": "newfont1 icon-duolanzhang",
    "048": "newfont1 icon-qimojiezhang",
    "049": "newfont1 icon-indmysalary",
    "050": "newfont1 icon-indsalarymanage",
    "051": "newfont1 icon-jiezhang",
    "053": "icon-indbmicon31",
    "055": "icon-indbmicon33",
    "058": "newfont1 icon-caiwubaobiao",
    "059": "icon-indjiekou", // 接口 平台
    "060": "iconfont icon-indplancenter", // 我的方案
    "061": "iconfont icon-indplantrend", // 销售与趋势
    "062": "iconfont icon-folder", // 我的文件夹
    "063": "icon-indshuiwubaobiao" // 税务报表
  };
  return map[iconCode];
}

/**
 * @description 根据图标的Code返回对应需要跳转的界面的path
 * @param iconCode
 */
function iconReplaceUrl(iconCode) {
  const map = {
    "2_12": "/mdTotalPic.do", // 智能看板
    "2_13": "/toHelpUser.do", // 帮助中心
    // "2_14": "", //方案中心
    // "2_15":"",// 税务管理
    998: "/indexSetting.do", // 自定义页面，PC自用
    "000": "/index.do", // "首页 PC自用"
    "001": "", // "行程预订 APP用"
    "002": "/chat.do", // "企业沟通"
    "003": "/toBillApply.do", // "事项申请"
    "005": "/toTripList.do", // "我的行程"
    "006": "/attDetail.do", // "考勤签到"
    "007": "/toRules.do", // "文档管理"
    "009": "/toBillClose.do", // "申请单完结"
    "010": "/toReportList.do", // "报表查询"
    "011": "/toWaitDo.do", // "待办事项"
    "012": "/toEnNoread.do", // "未读通知"
    "013": "/toEnRead.do", // "已读通知"
    "014": "/toEnBill.do", // "我的表单"
    "015": "/toEnOrder.do", // "我的订单"
    "016": "/toEnHandle.do", // "已办事项"
    "017": "/chat.do", // "发起沟通 APP用"
    "018": "/toPersonnelInfo.do", // "人事信息"
    "019": "/toInvoiceUserManage.do", // "我的票夹"
    "021": "/planeIndex.do", // "机票预订"
    "022": "/hotelIndex.do", // "酒店预订"
    "023": "/toDdCarIndex.do", // "用车预订"
    "024": "/toTripOrder.do", // "订单查询"
    "025": "/voucherGenerate.do", // "凭证生成"
    "026": "/voucherHandle.do", // "凭证处理"
    "027": "/voucherOutput.do", // "凭证输出"
    "028": "/toClientTicketApply.do", // "开票申请"
    "029": "/balanceTotal.do", // "结算汇总"
    "030": "/bmUserAdmin.do", // "业务管理"
    "031": "/interfaceUser.do", // "接口管理"
    "032": "/toDownCenter.do", // "下载中心"
    "033": "/toIndexCalendar.do", // "日历
    "034": "/myEn.do", // "我的河洛
    "035": "/toIndexForum.do", // "公司圈
    "036": "/toModelUserList.do",
    370: "/toReportList.do",
    "038": "/toEnMdBusiness.do",
    "039": "/vcUserList.do",
    "040": "/vcEntryViewList.do",
    "041": "凭证输出",
    "042": "/vcBalanceList.do",
    "043": "/vcGeneralList.do",
    "044": "/vcDetailGeneralList.do",
    "045": "/vcAuxiliaryBalanceList.do",
    "046": "/vcAuxiliaryDetailList.do",
    "047": "/vcMultipleList.do",
    "048": "/toFinalCarryOver.do",
    "049": "/myPaySlip.do",
    "050": "/myPaySlipMamager.do",
    "051": "/toSettleAccounts.do",
    // "053":"/myResourceMaintain.do",
    "055": "/myGroup.do",
    "058": "/reportBi.do",
    "059": "/apiPlat",
    "060": "/toPlanUserList.do", // 我的方案
    "061": "/toSalesTrends.do", // 销售与趋势
    "062": "/toFold.do", // 我的文件夹
    "063": "/taxUserReport.do"
  };
  return map[iconCode];
}

/**
 * @description 根据图标的Code返回对应的背景颜色
 * @param iconCode
 */
function iconReplaceBg(iconCode) {
  const map = {
    "0_1": "#49BEF2", // 常用/首页",
    "1_1": "#FFAD2C", // 用户自定义",
    "2_1": "#3e90fe", // 商旅管理",
    "2_2": "#26C393", // 办公管理",
    "2_3": "#9367EB", // 工具应用",
    "2_4": "#F76B6B", // 凭证管理",
    "2_5": "#49BEF2", // 结算中心"
    "2_6": "#FFAD2C", // 其他",
    "2_7": "#3e90fe", // 我的河洛",
    "2_8": "#26C393", // 沟通",
    "2_9": "#9367EB", // 公司圈",
    "2_10": "#F76B6B", // 智能核算",
    "2_11": "#49BEF2", // 魔钥资源业务
    "2_12": "#FFAD2C", // 智能看板
    "2_13": "#3e90fe", // 帮助中心
    "2_14": "#26C393", // 方案中心
    "2_15": "#9367EB", // 税务管理
    "4_6": "#F76B6B", // 其他
    "001": "#49BEF2", // 分类
    "002": "#FFAD2C", // 企业沟通
    "003": "#3e90fe", // 事项申请
    300: "#26C393", // 事项申请单应用
    "005": "#9367EB", // 我的行程
    "006": "#F76B6B", // 考勤签到
    "007": "#49BEF2", // 文档管理
    "009": "#FFAD2C", // 申请单完结
    "010": "#3e90fe", // 报表查询
    "011": "#26C393", // 待办事项",
    "012": "#9367EB", // "未读通知"
    "013": "#F76B6B", // "已读通知"
    "014": "#49BEF2", // "我的表单"
    "015": "#FFAD2C", // "我的订单"
    "016": "#3e90fe", // 已办任务",
    "017": "#26C393", // 发起沟通
    "018": "#9367EB", // 人事信息
    "019": "#F76B6B", // 我的票夹
    "021": "#49BEF2", // 机票预订",
    "022": "#FFAD2C", // 酒店预订",
    "023": "#3e90fe", // 用车预订",
    "024": "#26C393", // 订单查询",
    "025": "#9367EB", // 凭证生成
    "026": "#F76B6B", // 凭证处理
    "027": "#49BEF2", // 凭证输出
    "028": "#FFAD2C", // 开票申请
    "029": "#3e90fe", // 结算汇总
    "030": "#26C393", // 业务管理
    "031": "#9367EB", // 接口管理
    "032": "#F76B6B", // 下载中心
    "033": "#49BEF2", // 日历
    "034": "#FFAD2C", // 我的河洛
    "035": "#3e90fe", // 公司圈
    360: "#26C393",
    "036": "#9367EB",
    "037": "#F76B6B",
    370: "#49BEF2",
    "038": "#FFAD2C",
    "039": "#3e90fe",
    "040": "#26C393",
    "041": "#9367EB", // 凭证输出
    "042": "#F76B6B",
    "043": "#49BEF2",
    "044": "#FFAD2C",
    "045": "#3e90fe",
    "046": "#26C393",
    "047": "#9367EB",
    "048": "#F76B6B",
    "049": "#49BEF2",
    "050": "#FFAD2C",
    "051": "#3e90fe",
    "053": "#26C393",
    "055": "#9367EB",
    "058": "#F76B6B",
    "059": "#49BEF2", // 接口 平台
    "060": "#FFAD2C", // 我的方案
    "061": "#3e90fe", // 销售与趋势
    "062": "#26C393", // 我的文件夹
    "063": "#9367EB" // 税务报表
  };
  return map[iconCode];
}

/**
 * @description 根据数据的Code返回对应的组件名称
 * @param code
 * @return {*}
 */
function componentReplace(code) {
  const map = {
    "002": "", // 企业沟通
    "003": "", // 事项申请
    "005": "", // 我的行程
    "006": "", // 考勤签到
    "007": "", // 文档管理
    "009": "", // 申请单完结
    "010": "", // 报表查询
    "011": "", // 待办事项
    "012": "", // 未读通知
    "013": "", // 已读通知
    "014": "", // 我的表单
    "015": "", // 我的订单
    "016": "", // 已办事项
    "033": "", // 日历你
    "036": "", // 业务建模应用
    "038": "", // 我的业务
    "062": "", // 我的文件夹
    "065": "" // 主题应用
  };
  return map[code];
}

export {
  iconReplaceName,
  iconReplaceUrl,
  iconReplaceBg,
  componentReplace
};
