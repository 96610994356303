<!--
 * @Author: wuqi
 * @Date: 2021-12-30 10:50:40
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-03-04 11:30:47
 * @Description:
-->
<template>
  <div class="template-warp-box" >
    <el-dropdown   placement='bottom-start'  v-if="!isKanBan" >
      <en-button icon="add" class='add-template'>添加</en-button>
      <el-dropdown-menu     slot="dropdown">
        <el-dropdown-item   @click.native="openClassifyDialog('add')">分类</el-dropdown-item>
        <el-dropdown-item  v-if="menuData.type===1"  @click.native="openEditAppDialog">应用项</el-dropdown-item>
        <el-dropdown-item  v-if="menuData.type===8" @click.native="openEditLinkDialog">外部链接</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div :class='{"template-warp":!isKanBan,"isKanBan":isKanBan}' >
      <draggable class="list-group" :list="menuData.subsetList[0].dataList"  group="sec"
        @start="(evt)=>{start(menuData.subsetList[0].dataList,evt)}"
        @end="end" >
        <menu-define-item
          v-for="(item, index) in menuData.subsetList[0].dataList"
          :key="index"
          :check-box='isKanBan'
          :draggable="true"
          :menu-data="item"
          :parent-type='menuData.type'
          @delete='menuAppDelete(menuData.subsetList[0].dataList,item,index)'
          @edit="menuAppEdit(item,0,index)"
          :is-draggable="draggableData === item"
        />
      </draggable>
      <draggable class="list-group" :list="menuData.subsetList" group="first" >
        <div v-for="(classIfyItem, index0) in menuData.subsetList" :key="index0" class='list-group-item'>
          <template v-if="index0">
            <div v-if="classIfyItem.id!=='def'" class='group-item-title'>
              <en-icon :name='"erjifenlei"+classIfyItem.showIcon+"_1"' size='18px' :color='classIfyItem.color'></en-icon>
              <span class='title'>{{classIfyItem.name}}</span>
              <el-dropdown   placement='bottom-start'  v-if="!isKanBan" >
                <en-icon name='gengduo-liebiaoyouce' size='18px' style="color:#bbb"></en-icon>
                <el-dropdown-menu     slot="dropdown">
                  <el-dropdown-item   >
                    <en-icon-text name='bianji' size='16px' color='#26c494' :label='"编辑"' @click.native="openClassifyDialog('put',classIfyItem)"></en-icon-text>
                    </el-dropdown-item>
                  <el-dropdown-item>
                    <en-icon-text name='shanchu' size='16px' color='#f36e6b' :label='"删除"' @click.native="deleteClassIfy(classIfyItem,index0)"></en-icon-text>
                    </el-dropdown-item>

                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <draggable class="list-group" :list="classIfyItem.dataList" :key="index0" group="sec"
                @start="(evt)=>{start(classIfyItem.dataList,evt)}"
                @end="end" >
                <menu-define-item
                  v-for="(item, index) in classIfyItem.dataList"
                  :key="index"
                  :check-box='isKanBan'
                  :draggableFlg="true"
                  :menu-data="item"
                  :parent-type='menuData.type'
                  @delete='menuAppDelete(classIfyItem.dataList,item,index)'
                  @edit="menuAppEdit(item,index0,index)"
                  :is-draggable="draggableData === item"
                />
            </draggable>
          </template>

        </div>

      </draggable>
    </div>
    <!-- 添加分类 -->
    <edit-classify-dialog
      :visible.sync="dialogClassifyVisible"
      :type='classEditType'
      :info='curClassIfy'
      :configMenuDatas='configMenuDatas'
      @complete='classifyComplete'></edit-classify-dialog>
    <!-- 添加应用项 -->
    <edit-app-dialog
      :visible.sync="appDialogVisible"
      :configMenuDatas='configMenuDatas'
      @complete='appComplete'></edit-app-dialog>
    <!-- 添加外部链接 -->
    <edit-link-dialog
      :visible.sync="linkDialogVisible"
      :init-data='editLink'
      @complete='linkComplete'></edit-link-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { cloneDeep } from "lodash";
import enIconText from "@/components/en-icon-text";
import menuDefineItem from "./menu-define-item";
import editClassifyDialog from "./edit-classify-dialog";
import editAppDialog from "./edit-app-dialog";
import editLinkDialog from "./edit-link-dialog";

export default {
  name: "menu-subsetlist",
  data() {
    return {
      dialogClassifyVisible: false,
      appDialogVisible: false,
      linkDialogVisible: false,
      classEditType: "add",
      editLink: {},
      draggableData: null,
      curClassIfy: {},
      menuData: { subsetList: [{ dataList: [] }] }
    };
  },
  components: {
    draggable,
    menuDefineItem,
    editClassifyDialog,
    editAppDialog,
    editLinkDialog,
    enIconText
  },
  props: {
    menu: {
      type: Object,
      default() {
        return {};
      }
    },
    isKanBan: {
      type: Boolean,
      default: false
    },
    configMenuDatas: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
  },
  created() {
  },
  mounted() {
  },
  watch: {
    menu: {
      handler(nVal) {
        this.menuData = cloneDeep(nVal);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    start(list, evt) {
      this.draggableData = list[evt.oldIndex];
    },
    end() {
      this.menuData.dataList.sort((a, b) => {
        const aSort = this.menuData.subsetList[0].dataList.findIndex((v) => v.refId === a.refId);
        const bSort = this.menuData.subsetList[0].dataList.findIndex((v) => v.refId === b.refId);
        return aSort - bSort;
      });
      this.draggableData = null;
      this.updateClassifyList();
      this.$emit("change", this.menuData);
    },
    openClassifyDialog(type, data) {
      this.classEditType = type;
      this.curClassIfy = data;
      this.dialogClassifyVisible = true;
    },
    // 添加应用项模块弹框
    openEditAppDialog() {
      this.appDialogVisible = true;
    },
    // 添加外部链接弹框
    openEditLinkDialog() {
      this.editLink = {};
      this.linkDialogVisible = true;
    },

    // 更新分类信息 用于监听本地subsetlist更新后 同步分类信息
    updateClassifyList() {
      this.menuData.classifyList = this.menuData.subsetList.filter((item) => item.id !== "def").map((v) => {
       const classIfy = { ...v };
       delete classIfy.refDataList;
       const ids = v.dataList.map((t) => t.refId);
       classIfy.refDataIds = Array.from(new Set(ids)).join();
       return classIfy;
      });
    },

    // 删除菜单中的模块
    menuAppDelete(list, menu, ind) {
      const dataListInd = this.menuData.dataList.findIndex((v) => v.refId === menu.refId);
      list.splice(ind, 1);
      this.menuData.dataList.splice(dataListInd, 1);
      this.$emit("change", this.menuData);
    },
    // 删除分类
    deleteClassIfy(classInfo, ind) {
      if (classInfo.dataList?.length) {
        const defList = this.menuData.subsetList.find((v) => v.id === "def");
        defList.dataList = defList.dataList.concat(classInfo.dataList);
      }
      this.menuData.subsetList.splice(ind, 1);
      this.end();
    },
    // 编辑子菜单模块
    menuAppEdit(item, subIndex, index) {
      this.linkDialogVisible = true;
      this.editLink = {
       data: [item], subIndex, index, type: "edit"
      };
    },
    // 编辑分类
    classifyComplete(data) {
      const classInfo = { ...data, refDataIds: "" };
      if (this.classEditType === "add") { // 新增
        this.menuData.classifyList.push(classInfo);
      } else if (this.classEditType === "put") { // 编辑
        this.menuData.classifyList.forEach((item) => {
          if (item.id === data.id || (item.classifyId === data.classifyId && !item.id && !data.id)) {
            item.showIcon = data.showIcon;
            item.color = data.color;
            item.name = data.name;
          }
        });
      }
      this.subsetList(this.menuData);// 编辑完分类后 要同步更新子菜单subsetlist，  subsetlist字段仅用于本地管理 不提交后台
      this.dialogClassifyVisible = false;
      this.$emit("change", this.menuData);
    },
    // 处理菜单数据
    subsetList(data) {
        // 根据 存储的 二级分类
        const classifyList = cloneDeep(data.classifyList || []);
        // {name:"",id:"def",color:"",showIcon:"",refDataIds,refDataList:[]}
        const clonedataList = cloneDeep(data.dataList);
        // 循环 二级分类 处理数据
        classifyList.forEach((v) => {
            v.dataList = [];
            // 字符串变 数字
            v.refDataList = v.refDataIds.split(",");
            v.refDataList.forEach((vId) => {
             const classItem = clonedataList.find((item, ind) => item.refId === vId);
             if (classItem && !classItem.referji) {
               v.dataList.push(classItem);
               classItem.referji = true;
             }
            });
        });

        const def = { name: "默认", id: "def", dataList: [] };
        def.dataList = clonedataList.filter((v) => !v.referji);

        classifyList.unshift(def);
        data.subsetList = classifyList;
    },
    // 添加外部链接
    linkComplete(outLinks, editInfo) {
      outLinks.forEach((item) => {
        item.isShow = 0;
        const refIndex = this.menuData.dataList.findIndex((v) => v.refId === item.refId);// 检测外层是否有编辑项 refId相同表示有编辑项 则覆盖
        if (editInfo.type === "edit") {
          this.menuData.dataList[refIndex] = item;
          this.menuData.subsetList[editInfo.subIndex].dataList[editInfo.index] = item;
        } else {
          this.menuData.dataList.push(item);
          this.menuData.subsetList[0].dataList.push(item);
        }
      });
      this.editInfo = {};
      this.linkDialogVisible = false;
      this.$emit("change", this.menuData);
    },
    // 添加应用项回调
    appComplete(apps) {
      console.log(apps,'xxxxx',this.menuData.dataList);
      apps.forEach((item) => {
        item.isShow = 0;
        if (item.children || this.menuData.dataList.find((v) => v.refId === item.value)) { // 避免重复和屏蔽一级菜单
          return;
        }
        this.menuData.dataList.push(item.data);
        this.menuData.subsetList[0].dataList.push(item.data);// 默认def子菜单要同步更新
      });
      this.appDialogVisible = false;
      this.$emit("change", this.menuData);
    }
  }
};
</script>

<style lang='scss' scoped>
.add-template{
  margin: 0;
}
.template-warp-box{
  // padding-top: 16px;
}
.template-warp{
  // box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  min-height: 100px;
  // margin: 20px 20px 0 0;
  // position: absolute;
  z-index: 101;
  width: 50vw;
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #E8ECF2;
  border-radius: 4px;
}
.isKanBan{
  margin-left: 16px;
}
.list-group{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .group-item-title{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .title{
      margin: 0 10px;
    }
    /deep/ .el-dropdown{
      line-height: 1;
    }
  }
}
</style>
